import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { login } from "../controllers/smartcar";

import CButton from "../components/button";

import Onboarding1SVG from "../assets/images/onboarding_1.svg";

function SConnectVehicle() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center sm:h-[100dvh] h-[90dvh] w-[100dvw]">
      <div className="flex flex-col md:h-[75dvh] md:w-[45dvw] h-[100dvh] w-[100dvw] sm:h-[80dvh] sm:w-[60dvw] bg-container-background p-[2%] overflow-x-scroll sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%]">
          <p className="text-[20px] font-[800]">{t("connected_vehicle_2")}</p>
          <div className="h-[1%]" />
          <p className="text-[16px] font-[500]">{t("connected_vehicle_3")}</p>
          <div className="h-[1%]" />
          <p className="text-[16px] font-[500]">{t("connected_vehicle_4")}</p>
          <div className="h-[1%]" />
          <img
            src={Onboarding1SVG}
            className="h-[40%] object-cover self-center"
          />
          <CButton
            className="w-[100%]"
            text={t("connected_vehicle_5")}
            onClick={() => dispatch(login())}
          />
          <div className="h-[1%]" />
          <div className="relative h-[100%] w-[100%]">
            <div className="absolute bottom-0 h-[100%] w-[100%] flex flex-col items-center justify-end">
              <p className="text-[14px] font-[500]">
                {t("connected_vehicle_6")}
              </p>
              <div className="h-[10%]" />
              <CButton
                className="w-[100%]"
                style={{
                  backgroundColor: "#D9D9D9",
                  color: "#000000",
                }}
                text={t("connected_vehicle_7")}
                onClick={() => {
                  navigate("/pin_vehicle_location");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SConnectVehicle;
