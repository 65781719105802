import { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { login1 } from "../controllers/auth";

import CInput from "../components/input";
import CButton from "../components/button";

import InfoSVG from "../assets/images/info.svg";

function SLogin1() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.auth.isLoading);

  const [phone, setPhone] = useState("");

  const onClick = async () => {
    const res = await dispatch(
      login1({
        phone,
      })
    );
    if (res.payload) {
      navigate("/login_2", {
        state: { phone },
      });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-[100dvh] w-[100dvw]">
      <div className="flex flex-col md:h-[75dvh] md:w-[45dvw] sm:h-[80dvh] sm:w-[60dvw] h-[100dvh] w-[100dvw] bg-container-background p-[2%] overflow-x-scroll sm:rounded-[10px] rounded-none">
        <p className="text-[18px] font-[700] self-center">{t("login_1_1")}</p>
        <div className="flex flex-col justify-start h-[100%] w-[100%]">
          <div
            className={`flex flex-row items-center justify-start my-[2%] p-[1%] w-[100%] rounded-[5px] min-h-[10%]`}
          >
            <img src={InfoSVG} className="h-[20px] w-[20px]" />
            <div className="w-[2%]" />
            <p className="text-[16px] font-[600]">{t("login_1_2")}</p>
          </div>
          <div className="h-[2%]" />
          <div className="flex flex-row items-center justify-start w-[100%]">
            <p className="text-[16px] font-[600] pt-[1%]">+46</p>
            <div className="w-[2%]"></div>
            <CInput
              label={t("login_1_3")}
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              className="mb-[14px] mt-[5px]"
              placeholder={t("login_1_4")}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  onClick();
                  return;
                }
              }}
            />
          </div>
          <div className="relative h-[100%] w-[100%]">
            <div className="absolute bottom-0 h-[100%] w-[100%] flex flex-col items-center justify-end">
              <CButton
                className="my-[14px] w-[100%]"
                text={t("login_1_5")}
                onClick={onClick}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SLogin1;
