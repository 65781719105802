import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedRouteIndex: null,
};

export const controlBarSlice = createSlice({
  name: "controlBar",
  initialState,
  reducers: {
    setSelectedRouteIndex: (state, action) => {
      state.selectedRouteIndex = action.payload;
    },
  },
});

export const { setSelectedRouteIndex } = controlBarSlice.actions;

export default controlBarSlice.reducer;
