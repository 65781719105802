import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { createContactSupport } from "../controllers/contact_support";

import CInput from "../components/input";
import CButton from "../components/button";
import CContainer from "../components/container";

function SContactSupport() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  return (
    <CContainer>
      <div className="flex flex-col items-center justify-center sm:h-[100dvh] h-[90dvh] w-[100dvw]">
        <div className="flex flex-col md:h-[75dvh] md:w-[45dvw] h-[100dvh] w-[100dvw] sm:h-[80dvh] sm:w-[60dvw] bg-container-background p-[2%] overflow-x-scroll sm:rounded-[10px] rounded-none">
          <p className="text-[18px] font-[700] self-center">
            {t("contact_support_2")}
          </p>
          <div className="flex flex-col justify-start h-[100%] w-[100%]">
            <CInput
              label={t("contact_support_3")}
              className="mb-[14px] mt-[5px]"
              placeholder={t("contact_support_4")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
            />
            <div className="h-[2%]" />
            <CInput
              label={t("contact_support_5")}
              className="mb-[14px] mt-[5px]"
              placeholder={t("contact_support_6")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
            <div className="h-[2%]" />
            <div className="flex flex-col justify-start items-start">
              <p className="text-[12px]">{t("contact_support_7")}</p>
              <textarea
                className="bg-[#F3F6FF] p-[10px] w-[100%] text-[14px] outline-0 rounded-[20px] h-[20dvh] resize-none"
                placeholder={t("contact_support_8")}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                type="text"
              />
            </div>
            <div className="relative h-[100%] w-[100%]">
              <div className="absolute bottom-0 h-[100%] w-[100%] flex flex-col items-center justify-end">
                <CButton
                  className="w-[100%] my-[14px]"
                  text={t("contact_support_9")}
                  onClick={async () => {
                    const success = await dispatch(
                      createContactSupport({
                        name: name,
                        email: email,
                        message: message,
                      })
                    );
                    if (success) {
                      navigate(-1);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CContainer>
  );
}

export default SContactSupport;
