import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as AppNetworkRest from "../utils/network_rest";
import toast from "react-hot-toast";

const { nullValidator, validate, translate } = require("../utils/validators");

const initialState = {
  promoCode: null,
};

const getPromoCode = createAsyncThunk(
  "promoCodes/getPromoCode",
  async (args) => {
    try {
      const { title } = args;

      const valError = validate([
        () => nullValidator(translate({ placeholder: "vk_7" }), title),
      ]);
      if (valError) {
        toast.error(valError);
        return;
      }

      const promoCode = await AppNetworkRest.getPromoCode(args);

      if (promoCode) {
        toast.success(translate({ placeholder: "toast_2" }));
      }

      return promoCode;
    } catch (e) {
      console.log(e, "e");
      return null;
    }
  }
);

export const promoCodesSlice = createSlice({
  name: "promoCode",
  initialState,
  reducers: {
    setPromoCode: (state, action) => {
      state.promoCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPromoCode.fulfilled, (state, action) => {
      state.promoCode = action.payload;
    });
  },
});

export const { setPromoCode } = promoCodesSlice.actions;

export { getPromoCode };

export default promoCodesSlice.reducer;
