import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  getSubscription,
  cancelSubscription,
} from "../controllers/subscription";

import CContainer from "../components/container";
import CButton from "../components/button";

import { subscriptionStatuses } from "../utils/constants";

import BellSVG from "../assets/images/bell.svg";
import OnBoarding2SVG from "../assets/images/onboarding_2.svg";

function SSubscription() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const subscription = useSelector((state) => state.subscription.subscription);

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    dispatch(getSubscription());
  };

  return (
    <CContainer>
      <div className="flex flex-row items-center justify-center md:h-[75%] md:w-[45%] sm:h-[80%] sm:w-[60%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] sm:m-[0] my-[20%] overflow-scroll">
          {subscription ? (
            <>
              <div
                className={`flex flex-col bg-[#F3F6FF] my-[2%] p-[1%] w-[100%] rounded-[10px]`}
              >
                <div className="flex flex-row items-center justify-between">
                  <img src={BellSVG} className="h-[50px] w-[50px]" />
                  <p
                    className={`text-[14px] font-[800] ${
                      subscription.status == subscriptionStatuses.active
                        ? "text-green-800"
                        : "text-[#E53935]"
                    }`}
                  >
                    {subscription.status.toUpperCase()}
                  </p>
                </div>

                <div className="h-[10px]" />
                <p className="text-[20px] font-[800]">
                  {subscription.title}
                  {t("subscription_2")}
                </p>
                <div className="h-[20px]" />
                <p className="text-[16px] font-[600]">
                  {t("subscription_3")}{" "}
                  {new Intl.DateTimeFormat("en-US", {
                    dateStyle: "long",
                  }).format(subscription.expiry)}{" "}
                  .{" "}
                  {`${
                    subscription.paymentMethod == "signup"
                      ? t("subscription_3_1")
                      : subscription.status == subscriptionStatuses.active
                      ? t("subscription_4")
                      : t("subscription_5")
                  }`}{" "}
                </p>
                <div className="h-[30px]" />
                <CButton
                  text={t("subscription_6")}
                  onClick={() => {
                    navigate("/payment_plans");
                  }}
                />
                {subscription.status == subscriptionStatuses.active ? (
                  <>
                    <div className="h-[10px]" />
                    <CButton
                      text={t("subscription_7")}
                      onClick={() => {
                        window.modal_cancel_subscription.showModal();
                      }}
                      className="bg-transparent border-primary border-[1px]"
                      style={{
                        color: "black",
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}

                <div className="h-[10px]" />
                <CButton
                  text={t("subscription_7_1")}
                  onClick={() => {
                    navigate("/configure_notifications");
                  }}
                />
                <div className="h-[10px]" />
              </div>
              <img
                src={OnBoarding2SVG}
                className="sm:h-[35%] h-[40%] object-cover self-center"
              />
              <MCancelSubscription />
            </>
          ) : (
            <p className="text-[16px] font-[600]">{t("subscription_8")}</p>
          )}
          <div className="h-[2%]" />
        </div>
      </div>
    </CContainer>
  );
}

const MCancelSubscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <dialog id="modal_cancel_subscription" className="modal">
        <form method="dialog" className="modal-box bg-white">
          <div className="flex flex-col items-center justify-center bg-white">
            <p className="text-[18px] font-[800]">{t("subscription_9")}</p>
            <div className="h-[20px]" />
            <p className="text-[14px] font-[600]">{t("subscription_10")}</p>
            <div className="h-[30px]" />
            <CButton
              className="w-[20hvw]"
              text={t("subscription_11")}
              onClick={() => {
                window.modal_cancel_subscription.close();
              }}
            />
            <div className="h-[10px]" />
            <p
              className="text-[14px] font-[600] text-[#E53935] cursor-pointer"
              onClick={async () => {
                window.modal_cancel_subscription.close();
                const res = await dispatch(cancelSubscription());
                if (res.payload) {
                  navigate("/");
                }
              }}
            >
              {t("subscription_12")}
            </p>
            <div className="h-[10px]" />
          </div>
        </form>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
};

export default SSubscription;
