const locales = {
  swedish: "sv",
  english: "en",
};

const subscriptionStatuses = {
  active: "active",
  cancelled: "cancelled",
};

const paymentMethods = {
  signup: "signup",
  klarna: "klarna",
  appleInAppPurchases: "apple_in_app_purchases",
  googleInAppPurchases: "google_in_app_purchases",
  fullDiscount: "full_discount",
};

module.exports = {
  locales,
  subscriptionStatuses,
  paymentMethods,
};
