import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import * as AppNetworkRest from "../utils/network_rest";
import * as AppRoutes from "../utils/routes";
import localforage from "localforage";

const {
  nullValidator,
  phoneValidator,
  validate,
  translate,
} = require("../utils/validators");

const initialState = {
  router: AppRoutes.localesRouter,
  isLoading: false,
};

const login1 = createAsyncThunk("auth/login1", async (args) => {
  const { phone } = args;

  const valError = validate([
    () => phoneValidator(translate({ placeholder: "vk_1" }), phone),
  ]);
  if (valError) {
    toast.error(valError);
    return;
  }

  const str = await AppNetworkRest.login1(args);

  if (str) {
    toast.success(str);
    return true;
  }

  return false;
});

const login2 = createAsyncThunk("auth/login2", async (args) => {
  const { phone, code } = args;

  const valError = validate([
    () => phoneValidator(translate({ placeholder: "vk_1" }), phone),
    () => nullValidator(translate({ placeholder: "vk_2" }), code),
  ]);
  if (valError) {
    toast.error(valError);
    return;
  }

  const appUser = await AppNetworkRest.login2(args);

  if (appUser) {
    await appUser.cache();
    toast.success(translate({ placeholder: "toast_1" }));
  }

  return appUser;
});

const sync = createAsyncThunk("auth/sync", async (args) => {
  const appUser = await AppNetworkRest.sync(args);

  if (appUser) {
    await appUser.cache();
  }

  return appUser;
});

const logout = createAsyncThunk("auth/logout", async (args) => {
  await localforage.removeItem("appUser");
  return;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRouter: (state, action) => {
      state.router = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login1.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(login1.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(login2.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(login2.fulfilled, (state, action) => {
      if (action.payload) {
        state.router = AppRoutes.userRouter;
      }
      state.isLoading = false;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.router = AppRoutes.authRouter;
      window.location = "/";
    });
  },
});

export const { setRouter } = authSlice.actions;

export { login1, login2, sync, logout };

export default authSlice.reducer;
