import { createSlice } from "@reduxjs/toolkit";
import * as AppNetworkRest from "../utils/network_rest";

import { locales } from "../utils/constants";

const initialState = {
  isLocalesLoaded: false,
  locale: locales.swedish,
};

const loadLocaleENUS = async () => {
  try {
    const locale = await AppNetworkRest.loadLocaleENUS();

    return locale;
  } catch (e) {
    console.log(e, "e");
    return null;
  }
};

const loadLocaleSVSE = async () => {
  try {
    const locale = await AppNetworkRest.loadLocaleSVSE();

    return locale;
  } catch (e) {
    console.log(e, "e");
    return null;
  }
};

export const localeSlice = createSlice({
  name: "locale",
  initialState,
  reducers: {
    setLocale: (state, action) => {
      state.locale = action.payload.locale;
      localStorage.setItem("locale", action.payload.locale);
      action.payload.i18n.changeLanguage(action.payload.locale);
    },
    setIsLocalesLoaded: (state, action) => {
      state.isLocalesLoaded = action.payload;
    },
  },
});

export const { setLocale, setIsLocalesLoaded } = localeSlice.actions;

export { loadLocaleENUS, loadLocaleSVSE };

export default localeSlice.reducer;
