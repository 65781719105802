import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import * as AppNetworkRest from "../utils/network_rest";

const initialState = {
  subscription: null,
};

const getSubscription = createAsyncThunk(
  "subscription/getSubscription",
  async (args, { getState }) => {
    try {
      const subscription = await AppNetworkRest.getSubscription();
      return subscription;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const cancelSubscription = createAsyncThunk(
  "subscription/cancelSubscription",
  async (args, { getState }) => {
    try {
      const str = await AppNetworkRest.cancelSubscription();

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSubscription.fulfilled, (state, action) => {
      if (action.payload) {
        state.subscription = action.payload;
      }
    });
  },
});

export { getSubscription, cancelSubscription };

export default subscriptionSlice.reducer;
