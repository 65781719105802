import localforage from "localforage";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { useMap, useMapEvents } from "react-leaflet/hooks";
import "leaflet/dist/leaflet.css";
import { useTranslation } from "react-i18next";

import { pinVehicleLocation } from "../controllers/smartcar";
import { setParkingInfo, getParkingInfo } from "../controllers/parking";

import CButton from "../components/button";
import CLoader from "../components/loader";

import ParkingMarkerSVG from "../assets/images/parking_marker.svg";
import PinVehicleLocationInfoSVG from "../assets/images/pin_vehicle_location_info.svg";
import ServiceDayTimeSVG from "../assets/images/service_day_time.svg";
import ServiceDayCanStaySVG from "../assets/images/service_day_can_stay.svg";

let mapInstance = null;

function SPinVehicleLocation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const parkingInfo = useSelector((state) => state.parking.parkingInfo);

  const [center, setCenter] = useState({ lat: 59.315961, lng: 18.056126 });
  const [zoom, setZoom] = useState(18);
  const [clickMarker, setClickMarker] = useState(null);

  const parkingMarkerIcon = new L.Icon({
    iconUrl: ParkingMarkerSVG,
    iconSize: new L.Point(40, 40),
    iconAnchor: new L.Point(21, 40),
  });

  const onClickMap = async (e) => {
    setClickMarker({
      latitude: e.latlng.lat,
      longitude: e.latlng.lng,
    });
    dispatch(
      getParkingInfo({
        latitude: e.latlng.lat,
        longitude: e.latlng.lng,
      })
    );
  };

  useEffect(() => {
    return () => (mapInstance = null);
  }, []);

  useEffect(() => {
    dispatch(setParkingInfo(null));
    let appUser = localStorage.getItem("AppStorage/appUser");
    appUser = JSON.parse(appUser);
    if (appUser.isCarConnected || appUser.isLocationConnected) {
      dispatch(getParkingInfo());
    }
  }, []);

  useEffect(() => {
    if (parkingInfo) {
      setCenter({
        lat: parkingInfo.location.latitude - 0.0005,
        lng: parkingInfo.location.longitude,
      });
      if (mapInstance) {
        mapInstance.flyTo({
          lat: parkingInfo.location.latitude - 0.0005,
          lng: parkingInfo.location.longitude,
        });
      }
      setClickMarker({
        latitude: parkingInfo.location.latitude,
        longitude: parkingInfo.location.longitude,
      });
    }
  }, [parkingInfo]);

  return (
    <div className="flex flex-col items-center justify-center h-[100dvh] w-[100dvw] bg-background">
      <div className="relative z-0 h-[100%] w-[100%]">
        {center ? (
          <MapContainer
            center={center}
            zoom={zoom}
            scrollWheelZoom={true}
            zoomControl={false}
            className="h-[100%] w-[100%] sticky"
          >
            <GetMapInstance />
            <Events onClick={onClickMap} />
            <TileLayer
              url={`https://api.maptiler.com/maps/streets-v2/256/{z}/{x}/{y}@2x.png?key=${process.env.REACT_APP_MAPTILER_API_KEY}`}
            />
            {clickMarker ? (
              <Marker
                position={[clickMarker.latitude, clickMarker.longitude]}
                icon={parkingMarkerIcon}
              >
                <Popup>{`${clickMarker.latitude.toFixed(
                  4
                )},${clickMarker.longitude.toFixed(4)}`}</Popup>
              </Marker>
            ) : (
              <></>
            )}
          </MapContainer>
        ) : (
          <CLoader />
        )}
      </div>
      <CParkingAddress parkingInfo={parkingInfo} />
      <CParkingServiceDay parkingInfo={parkingInfo} clickMarker={clickMarker} />
    </div>
  );
}

function GetMapInstance({}) {
  const map = useMap();
  mapInstance = map;
  return null;
}

function Events({ onClick }) {
  const map = useMapEvents({
    click(e) {
      onClick(e);
    },
  });
}

function CParkingAddress({ parkingInfo }) {
  const { t } = useTranslation();
  return (
    <>
      {parkingInfo?.errorMessage ? (
        <></>
      ) : (
        <div
          className={`absolute z-1 sm:bottom-[22%] bottom-[20%] sm:w-[50%] w-[100%] sm:h-[16%] h-[20%] flex flex-col ${
            parkingInfo ? "items-start" : "items-center"
          } justify-start items-start sm:px-[1%] px-[2%] sm:py-[1%] py-[2%] ${
            parkingInfo ? "bg-[#000000]" : "bg-[#4D5DFA]"
          } overflow-scroll sm:rounded-[10px] rounded-t-[20px]`}
        >
          <div className="flex flex-col justify-start items-center h-[100%] w-[100%]">
            <div className="flex flex-row justify-start items-center h-[100%] w-[100%]">
              <div className="flex flex-row justify-center items-center h-[20%] sm:w-[10%] w-[20%]">
                <div className="flex flex-row justify-center items-center h-[50px] w-[50px] bg-container-background rounded-[25px]">
                  <img
                    src={PinVehicleLocationInfoSVG}
                    className="h-[40px] w-[40px] pt-[10%]"
                  />
                </div>
              </div>
              <div className="w-[2%]" />
              <div className="flex flex-col justify-center items-start">
                <p className="text-[16px] font-[600] text-white whitespace-nowrap">
                  {parkingInfo
                    ? t("pin_vehicle_location_1")
                    : t("pin_vehicle_location_2")}
                </p>
                <p className="text-[16px] text-white whitespace-nowrap">
                  {parkingInfo?.address ?? t("pin_vehicle_location_3")}
                </p>
              </div>
            </div>
            <div className="h-[50px]" />
          </div>
        </div>
      )}
    </>
  );
}

function CParkingServiceDay({ parkingInfo, clickMarker }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  return (
    <div
      className={`absolute z-2 sm:bottom-[2%] bottom-[0%] sm:w-[50%] w-[100%] h-[25%] flex flex-col ${
        parkingInfo ? "items-start" : "items-center"
      } sm:justify-center sm:px-[2%] sm:py-[1%] p-[5%] bg-white overflow-scroll sm:rounded-[10px] rounded-t-[20px]`}
    >
      {parkingInfo ? (
        parkingInfo?.errorMessage ? (
          <>
            <p className="text-[16px] font-[600]">
              {parkingInfo?.errorMessage}
            </p>
          </>
        ) : (
          <>
            <div className="h-[20px]" />
            <p className="text-[16px] font-[600]">{t("track_vehicle_2")}</p>
            <div className="flex flex-row justify-start items-center w-[100%]">
              <img
                src={ServiceDayTimeSVG}
                className="h-[16px] w-[16px] mr-[2%]"
              />
              <p className="text-[16px]">{parkingInfo.serviceDayMessage}</p>
            </div>
            <div className="h-[20px]" />
            <p className="text-[16px] font-[600]">{t("track_vehicle_3")}</p>
            <div className="flex flex-row justify-start items-center w-[100%]">
              <img
                src={ServiceDayCanStaySVG}
                className="h-[16px] w-[16px] mr-[2%]"
              />
              <p
                className={`text-[16px] ${
                  parkingInfo.canStayParked ? "text-[green]" : "text-[red]"
                }`}
              >
                {parkingInfo.canStayParked
                  ? t("pin_vehicle_location_5")
                  : t("pin_vehicle_location_6")}
              </p>
            </div>
            <div className="h-[50px]" />
            <CButton
              className="self-center"
              text={t("pin_vehicle_location_4")}
              onClick={async () => {
                const success = await dispatch(pinVehicleLocation(clickMarker));
                if (success) {
                  navigate("/track_vehicle");
                }
              }}
            />
          </>
        )
      ) : (
        <>
          <p className="text-[16px] font-[900]">...</p>
        </>
      )}
    </div>
  );
}

export default SPinVehicleLocation;
