import { createBrowserRouter } from "react-router-dom";

import SLocales from "../screens/locales";
import SSplash from "../screens/splash";
import SLogin1 from "../screens/login_1";
import SLogin2 from "../screens/login_2";
import SHome from "../screens/home";
import SConnectVehicle from "../screens/connect_vehicle";
import STrackVehicle from "../screens/track_vehicle";
import SNotifications from "../screens/notifications";
import SPaymentPlans from "../screens/payment_plans";
import SPaymentSuccess from "../screens/payment_success";
import SSettings from "../screens/settings";
import SSubscription from "../screens/subscription";
import SChangeVehicle from "../screens/change_vehicle";
import SPinVehicleLocation from "../screens/pin_vehicle_location";
import SConfigureNotifications from "../screens/configure_notifications";
import SChangeLanguage from "../screens/change_language";
import SHelp from "../screens/help";
import SFaqs from "../screens/faqs";
import SContactSupport from "../screens/contact_support";
import SPrivacyPolicy from "../screens/privacy_policy";

const localesRouter = createBrowserRouter([
  {
    path: "*",
    element: <SLocales />,
  },
]);

const splashRouter = createBrowserRouter([
  {
    path: "*",
    element: <SSplash />,
  },
]);

const authRouter = createBrowserRouter([
  {
    path: "*",
    element: <SSplash />,
  },
  {
    path: "/login_1",
    element: <SLogin1 />,
  },
  {
    path: "/login_2",
    element: <SLogin2 />,
  },
  {
    path: "/privacy_policy",
    element: <SPrivacyPolicy />,
  },
]);

const userRouter = createBrowserRouter([
  {
    path: "*",
    element: <SHome />,
  },
  {
    path: "/home",
    element: <SHome />,
  },
  {
    path: "/connect_vehicle",
    element: <SConnectVehicle />,
  },
  {
    path: "/track_vehicle",
    element: <STrackVehicle />,
  },
  {
    path: "/notifications",
    element: <SNotifications />,
  },
  {
    path: "/payment_plans",
    element: <SPaymentPlans />,
  },
  {
    path: "/payment_success",
    element: <SPaymentSuccess />,
  },
  {
    path: "/settings",
    element: <SSettings />,
  },
  {
    path: "/subscription",
    element: <SSubscription />,
  },
  {
    path: "/change_vehicle",
    element: <SChangeVehicle />,
  },
  {
    path: "/pin_vehicle_location",
    element: <SPinVehicleLocation />,
  },
  {
    path: "/configure_notifications",
    element: <SConfigureNotifications />,
  },
  {
    path: "/change_language",
    element: <SChangeLanguage />,
  },
  {
    path: "/help",
    element: <SHelp />,
  },
  {
    path: "/faqs",
    element: <SFaqs />,
  },
  {
    path: "/contact_support",
    element: <SContactSupport />,
  },
  {
    path: "/privacy_policy",
    element: <SPrivacyPolicy />,
  },
]);

export { localesRouter, splashRouter, authRouter, userRouter };
