import { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  getNotificationConfig,
  updateNotificationConfig,
} from "../controllers/notification_config";

import CInput from "../components/input";
import CButton from "../components/button";
import CContainer from "../components/container";

function SConfigureNotifications() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.auth.isLoading);
  const notificationConfig = useSelector(
    (state) => state.notificationConfig.notificationConfig
  );

  const [isPhone, setIsPhone] = useState(false);
  const [phone, setPhone] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    _init();
  }, []);

  useEffect(() => {
    if (notificationConfig) {
      if (notificationConfig.phoneNumber) {
        setIsPhone(true);
        setPhone(notificationConfig.phoneNumber);
      }
      if (notificationConfig.email) {
        setIsEmail(true);
        setEmail(notificationConfig.email);
      }
    }
  }, [notificationConfig]);

  const _init = () => {
    dispatch(getNotificationConfig());
  };

  return (
    <CContainer>
      <div className="flex flex-col items-center justify-center sm:h-[100dvh] h-[90dvh] w-[100dvw]">
        <div className="flex flex-col md:h-[75dvh] md:w-[45dvw] sm:h-[80dvh] sm:w-[60dvw] h-[100dvh] w-[100dvw] bg-container-background p-[2%] overflow-x-scroll sm:rounded-[10px] rounded-none">
          <p className="text-[18px] font-[700] self-center">
            {t("configure_notifications_2")}
          </p>
          <div className="flex flex-col justify-start h-[100%] w-[100%]">
            <div className="flex flex-row items-center justify-between min-h-[15%] bg-[#FFFFFF] p-[2%] rounded-[10px]">
              <p className="text-[18px] font-[700]">
                {t("configure_notifications_3")}
              </p>
              <input
                type="checkbox"
                className={`toggle toggle-lg ${
                  isPhone ? "toggle-success" : "toggle-error"
                }`}
                checked={isPhone}
                onChange={(e) => setIsPhone(e.target.checked)}
              />
            </div>
            {isPhone ? (
              <>
                <div className="h-[2%]" />
                <div className="flex flex-row items-center justify-start w-[100%]">
                  <p className="text-[16px] font-[600] pt-[1%]">+46</p>
                  <div className="w-[2%]"></div>
                  <CInput
                    label={t("configure_notifications_4")}
                    className="mb-[14px] mt-[5px]"
                    placeholder={t("configure_notifications_5")}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    type="text"
                  />
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="h-[2%]" />
            <div className="flex flex-row items-center justify-between min-h-[15%] bg-[#FFFFFF] p-[2%] rounded-[10px]">
              <p className="text-[18px] font-[700]">
                {t("configure_notifications_6")}
              </p>
              <input
                type="checkbox"
                className={`toggle toggle-lg ${
                  isEmail ? "toggle-success" : "toggle-error"
                }`}
                checked={isEmail}
                onChange={(e) => setIsEmail(e.target.checked)}
              />
            </div>
            {isEmail ? (
              <>
                <div className="h-[2%]" />
                <CInput
                  label={t("configure_notifications_7")}
                  className="mb-[14px] mt-[5px]"
                  placeholder={t("configure_notifications_8")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                />
              </>
            ) : (
              <></>
            )}
            <div className="relative h-[100%] w-[100%]">
              <div className="absolute bottom-0 h-[100%] w-[100%] flex flex-col items-center justify-end">
                <CButton
                  className="w-[100%] my-[14px]"
                  text={t("configure_notifications_9")}
                  onClick={async () => {
                    const res = await dispatch(
                      updateNotificationConfig({
                        phone: isPhone ? phone : null,
                        email: isEmail ? email : null,
                      })
                    );
                    if (res.payload) {
                      navigate(-1);
                    }
                  }}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CContainer>
  );
}

export default SConfigureNotifications;
