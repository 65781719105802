function CInput({
  label,
  placeholder,
  value,
  enableEyes,
  className,
  pClassName,
  inputClassName,
  style,
  onChange,
  onKeyDown,
  type,
  pattern,
  inputMode,
}) {
  return (
    <div
      className={`flex flex-col justify-start items-start ${className}`}
      style={style}
    >
      {label ? <p className={`text-[12px] ${pClassName}`}>{label}</p> : <></>}
      <input
        className={`bg-[#F3F6FF] p-[10px] w-[100%] text-[14px] outline-0 rounded-[20px] ${inputClassName}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        pattern={pattern}
        inputMode={inputMode}
        onKeyDown={onKeyDown}
      />
    </div>
  );
}

export default CInput;
