import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CContainer from "../components/container";

import { getFaqs } from "../controllers/faqs";

function SFAQs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedFaqID, setSelectedFaqID] = useState(null);

  const faqs = useSelector((state) => state.faqs.faqs);

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    dispatch(getFaqs());
  };

  return (
    <CContainer>
      <div className="flex flex-row items-center justify-center sm:h-[100dvh] h-[80dvh] w-[100dvw] bg-background">
        <div className="flex flex-col items-center justify-center md:w-[70dvw] sm:w-[60dvw] sm:h-[100dvh] h-[90dvh] w-[100dvw]">
          <div className="md:h-[75dvh] md:w-[45dvw] h-[100dvh] w-[100dvw] sm:h-[80dvh] sm:w-[60dvw] bg-container-background overflow-x-scroll sm:rounded-[10px] rounded-none">
            <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%]">
              <div className="sm:h-[0%] h-[10%]" />
              <p className="text-[18px] font-[700] self-center">
                {t("faqs_2")}
              </p>
              <div className="h-[2%]" />
              {faqs.map((_) => (
                <CFaqListTile
                  faq={_}
                  isSelected={selectedFaqID == _.id ? true : false}
                  onClick={() => setSelectedFaqID(_.id)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </CContainer>
  );
}

function CFaqListTile({ faq, isSelected, onClick }) {
  return (
    <div
      className={`flex flex-row ${
        isSelected ? "bg-[#F3F6FF]" : "bg-[#FFFFFF]"
      } my-[1%] w-[100%] rounded-[10px]`}
      onClick={onClick}
    >
      <div className="collapse bg-base-200">
        <input type="radio" name="my-accordion-1" checked={isSelected} />
        <div className="collapse-title text-xl font-medium">{faq.title}</div>
        <div className="collapse-content">
          <p>{faq.description}</p>
        </div>
      </div>
    </div>
  );
}

export default SFAQs;
