import CAppbar from "./appbar";
import CBottomNavbar from "./bottom_navbar";

function CContainer({ className, children }) {
  return (
    <div
      className={`flex flex-row items-center justify-center sm:h-[100dvh] h-[90dvh] w-[100dvw] bg-background ${
        className ?? ""
      }`}
    >
      <CAppbar className="z-[200]" />
      {children}
      <CBottomNavbar className="z-[100]" />
    </div>
  );
}

export default CContainer;
