import localforage from "localforage";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CContainer from "../components/container";

import { sync } from "../controllers/auth";
import { getVehicle, diconnectVehicle } from "../controllers/smartcar";

import CButton from "../components/button";

import Onboarding1SVG from "../assets/images/onboarding_1.svg";

function SChangeVehicle() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isCarConnected, setIsCarConnected] = useState(false);
  const [isLocationConnected, setIsLocationConnected] = useState(false);

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    let appUser = localStorage.getItem("AppStorage/appUser");
    appUser = JSON.parse(appUser);

    if (appUser.isCarConnected) {
      setIsCarConnected(true);
    }
    if (appUser.isLocationConnected) {
      setIsLocationConnected(true);
    }

    dispatch(getVehicle());
  };

  const vehicle = useSelector((state) => state.smartcar.vehicle);

  return (
    <CContainer>
      <div className="flex flex-col items-center justify-center sm:h-[100dvh] h-[90dvh] w-[100dvw]">
        <div className="flex flex-col md:h-[75dvh] md:w-[45dvw] h-[100dvh] w-[100dvw] sm:h-[80dvh] sm:w-[60dvw] bg-container-background p-[2%] overflow-x-scroll sm:rounded-[10px] rounded-none">
          <div className="flex flex-col items-start justify-start h-[100%] w-[100%]">
            <p className="text-[20px] font-[800]">{t("change_vehicle_2")}</p>
            <div className="h-[1%]" />
            <p className="text-[16px] font-[500]">
              {t("change_vehicle_3")}{" "}
              {`${vehicle ? `${vehicle.model} ${vehicle.year}` : "..."}`}
            </p>
            <div className="h-[1%]" />
            <img
              src={Onboarding1SVG}
              className="lg:h-[40%] h-[30%] object-cover self-center"
            />
            <div className="relative h-[100%] w-[100%]">
              <div className="absolute bottom-0 h-[100%] w-[100%] flex flex-col items-center justify-end">
                <CButton
                  className="w-[100%]"
                  text={
                    isLocationConnected
                      ? t("change_vehicle_4_1")
                      : t("change_vehicle_4")
                  }
                  onClick={() => navigate("/connect_vehicle")}
                />
                <div className="h-[4%]" />
                {isCarConnected ? (
                  <CButton
                    className="w-[100%]"
                    style={{
                      backgroundColor: "#EA3424",
                    }}
                    text={t("change_vehicle_5")}
                    onClick={() => {
                      window.modal_disconnect_vehicle.showModal();
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DisconnectVehicle />
    </CContainer>
  );
}

const DisconnectVehicle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <dialog id="modal_disconnect_vehicle" className="modal">
        <form method="dialog" className="modal-box bg-white">
          <div className="flex flex-col items-center justify-center bg-white">
            <p className="text-[18px] font-[800]">{t("change_vehicle_6")}</p>
            <div className="h-[20px]" />
            <p className="text-[14px] font-[600]">{t("change_vehicle_7")}</p>
            <div className="h-[30px]" />
            <CButton
              className="w-[20hvw]"
              text={t("change_vehicle_8")}
              onClick={() => {
                window.modal_disconnect_vehicle.close();
              }}
            />
            <div className="h-[10px]" />
            <p
              className="text-[14px] font-[600] text-[#E53935] cursor-pointer"
              onClick={async () => {
                window.modal_disconnect_vehicle.close();
                const res = await dispatch(diconnectVehicle());
                let appUser = await localforage.getItem("appUser");
                await dispatch(sync(appUser));
                if (res.payload) {
                  navigate("/");
                }
              }}
            >
              {t("change_vehicle_9")}
            </p>
            <div className="h-[10px]" />
          </div>
        </form>
        <form method="dialog" className="modal-backdrop">
          <button>{t("change_vehicle_10")}</button>
        </form>
      </dialog>
    </>
  );
};

export default SChangeVehicle;
