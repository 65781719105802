const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function noValidator() {
  return "";
}

function nullValidator(key, value) {
  let error;
  if (!value) {
    error = translate({ placeholder: "validators_1", key: key });
  }
  return error;
}

function emailValidator(key, value) {
  let error = nullValidator(key, value);
  if (error) {
    return error;
  }
  const match = value.match(emailRegex);
  if (!match) {
    error = translate({ placeholder: "validators_2", key: key });
  }
  return error;
}

function phoneValidator(key, value) {
  let error = nullValidator(key, value);
  if (error) {
    return error;
  }
  if (value?.toString()?.length != 9) {
    return translate({ placeholder: "validators_3", key: key, length: 9 });
  }
  const num = Number(value);
  if (isNaN(num) || !Number.isInteger(num) || num <= 0) {
    error = translate({ placeholder: "validators_2", key: key });
  }
  return error;
}

function lengthValidator(key, value, length) {
  let error = nullValidator(key, value);
  if (error) {
    return error;
  }
  const match = value.length >= length;
  if (!match) {
    error = translate({
      placeholder: "validators_4",
      key: key,
      length: length,
    });
  }
  return error;
}

function validate(validatorClosures) {
  const errors = [];

  for (let i = 0; i < validatorClosures.length; i++) {
    const f = validatorClosures[i];
    const error = f();
    if (error) {
      errors.push(error);
    }
  }

  if (errors.length > 0) {
    return errors[0];
  } else {
    return null;
  }
}

function translate({ placeholder, key, length }) {
  const locale = localStorage.getItem("locale");

  let localeMap;
  if (locale == "en") {
    localeMap = JSON.parse(localStorage.getItem("AppStorage/localeENUS"));
  } else if (locale == "sv") {
    localeMap = JSON.parse(localStorage.getItem("AppStorage/localeSVSE"));
  }

  if (!localeMap) {
    return placeholder;
  }

  let str = localeMap[placeholder];
  if (key) {
    str = str.replace("$key", key);
  }
  if (length) {
    str = str.replace("$length", length);
  }

  return str ?? placeholder;
}

export {
  noValidator,
  nullValidator,
  emailValidator,
  phoneValidator,
  lengthValidator,
  validate,
  translate,
};
