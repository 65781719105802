import i18n from "i18next";
import localforage from "localforage";
import { initReactI18next } from "react-i18next";

const initLocales = async () => {
  const localeENUS = await localforage.getItem("localeENUS");
  const localeSVSE = await localforage.getItem("localeSVSE");

  i18n.use(initReactI18next).init({
    lng: "sv",
    fallbackLng: "en",
    debug: true,
    resources: {
      en: {
        translation: localeENUS,
      },
      sv: {
        translation: localeSVSE,
      },
    },
  });

  return true;
};

export default initLocales;
