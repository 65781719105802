import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import * as AppNetworkRest from "../utils/network_rest";

const {
  nullValidator,
  emailValidator,
  validate,
  translate,
} = require("../utils/validators");

const initialState = {};

const createContactSupport = createAsyncThunk(
  "contactSupport/createContactSupport",
  async (args, { getState }) => {
    try {
      const { name, email, message } = args;

      const valError = validate([
        () => nullValidator(translate({ placeholder: "vk_3" }), name),
        () => emailValidator(translate({ placeholder: "vk_4" }), email),
        () => nullValidator(translate({ placeholder: "vk_5" }), message),
      ]);
      if (valError) {
        toast.error(valError);
        return;
      }

      const str = await AppNetworkRest.createContactSupport(args);

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

export const contactSupportSlice = createSlice({
  name: "contactSupport",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(createContactSupport.fulfilled, (state, action) => {});
  },
});

export { createContactSupport };

export default contactSupportSlice.reducer;
