import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { setSelectedRouteIndex } from "../controllers/control_bar";

import BNVHomeSelectedSVG from "../assets/images/bnv_home_selected.svg";
import BNVHomeUnselectedSVG from "../assets/images/bnv_home_unselected.svg";
import BNVNotificationsSelectedSVG from "../assets/images/bnv_notifications_selected.svg";
import BNVNotificationsUnselectedSVG from "../assets/images/bnv_notifications_unselected.svg";
import BNVSettingsSelectedSVG from "../assets/images/bnv_settings_selected.svg";
import BNVSettingsUnselectedSVG from "../assets/images/bnv_settings_unselected.svg";
import BNVHelpSelectedSVG from "../assets/images/bnv_help_selected.svg";
import BNVHelpUnselectedSVG from "../assets/images/bnv_help_unselected.svg";

const icons = [
  {
    title: "bottom_navbar_1",
    selectedIcon: BNVHomeSelectedSVG,
    unSelectedIcon: BNVHomeUnselectedSVG,
    route: "/",
  },
  {
    title: "bottom_navbar_2",
    selectedIcon: BNVNotificationsSelectedSVG,
    unSelectedIcon: BNVNotificationsUnselectedSVG,
    route: "/notifications",
  },
  {
    title: "bottom_navbar_3",
    selectedIcon: BNVSettingsSelectedSVG,
    unSelectedIcon: BNVSettingsUnselectedSVG,
    route: "/settings",
  },
  {
    title: "bottom_navbar_4",
    selectedIcon: BNVHelpSelectedSVG,
    unSelectedIcon: BNVHelpUnselectedSVG,
    route: "/help",
  },
];

function CBottomNavbar({ className }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedRouteIndex = useSelector(
    (state) => state.controlBar.selectedRouteIndex
  );

  return (
    <div
      className={`flex flex-row justify-evenly items-center h-[10dvh] w-[100%] bg-white ${className} absolute bottom-0 sm:hidden`}
    >
      {icons.map((_, index) => (
        <CBNVListTile
          title={t(_.title)}
          selectedIcon={_.selectedIcon}
          unSelectedIcon={_.unSelectedIcon}
          isSelected={index == selectedRouteIndex}
          onClick={() => {
            navigate(_.route);
            dispatch(setSelectedRouteIndex(index));
          }}
        />
      ))}
    </div>
  );
}

function CBNVListTile({
  title,
  selectedIcon,
  unSelectedIcon,
  isSelected,
  onClick,
}) {
  return (
    <div
      className="flex flex-col justify-between items-center cursor-pointer"
      onClick={onClick}
    >
      <img
        src={isSelected ? selectedIcon : unSelectedIcon}
        className="h-[20px] w-[20px]"
      />
      <p
        className={`text-[13px] ${
          isSelected ? "text-primary" : "text-[#D2D2D2]"
        }`}
      >
        {title}
      </p>
    </div>
  );
}

export default CBottomNavbar;
