import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SPrivacyPolicy() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center sm:h-[100dvh] h-[90dvh] w-[100dvw]">
      <div className="flex flex-col md:h-[75dvh] md:w-[45dvw] h-[100dvh] w-[100dvw] sm:h-[80dvh] sm:w-[60dvw] bg-container-background p-[2%] overflow-x-scroll sm:rounded-[10px] rounded-none">
        <p className="text-[18px] font-[700] self-center">
          {t("privacy_policy_2")}
        </p>
        <div className="flex flex-col justify-start h-[100%] w-[100%]">
          <div className="h-[2%]" />
          <p className="text-[18px] font-[600]">Privacy Policy for Qarlie</p>
          <p className="text-[16px] font-[600] italic">
            Last Updated: 29 November 2023
          </p>
          <p className="text-[14px]">1. Introduction</p>
          <p className="text-[14px]">
            Welcome to Qarlie. This Privacy Policy explains how we collect, use,
            and share your personal data when you use our app.
          </p>
          <p className="text-[14px]">2. Personal data that we collect</p>
          <p className="text-[14px]">
            a. Location Information: To help you track your car, you can provide
            us with the location of your car either through manually pinning it
            to the map or by connecting Qarlie to your car’s GPS for continuous
            tracking.
          </p>
          <p className="text-[14px]">
            b. Contact Information: We collect your mobile phone number for
            login purposes, device syncing and to send text message reminders.
            Providing your email address is optional for receiving reminders.
          </p>
          <p className="text-[14px]">3. How we use your personal data</p>
          <p className="text-[14px]">
            a. To provide, maintain, and improve the Qarlie app, including to
            track your car’s location.
          </p>
          <p className="text-[14px]">
            b. To communicate with you, such as sending reminders about moving
            your car on cleaning days.
          </p>
          <p className="text-[14px]">4. Sharing your information</p>
          <p className="text-[14px]">
            We do not share your personal data with third parties except as
            necessary to provide the service (e.g. sending reminders through a
            text message service provided by Twillio Inc or store data with
            Amazon Web Services).
          </p>
          <p className="text-[14px]">5. Data security</p>
          <p className="text-[14px]">
            We implement reasonable measures to secure your information and
            prevent unauthorized access, use or disclosure.
          </p>
          <p className="text-[14px]">6. Changes to this policy</p>
          <p className="text-[14px]">
            We may update this policy from time to time. The date at the top of
            the policy shows when the last update was made.
          </p>
          <p className="text-[14px]">7. Contact us</p>
          <p className="text-[14px]">
            Qarlie is provided by the Swedish company Greybear Solutions AB,
            corp. reg. number 559242-0243 registered in Stockholm. This is also
            the data controller. If you have any questions about this policy or
            the data we process about you, such as wanting us to rectify or
            delete it, please contact us at support@qarlie.com
          </p>
        </div>
      </div>
    </div>
  );
}

export default SPrivacyPolicy;
