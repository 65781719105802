import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import * as AppNetworkRest from "../utils/network_rest";
import { nullValidator, validate, translate } from "../utils/validators";

const initialState = {
  parkingInfo: null,
  areaParkingInfos: [],
};

const getParkingInfo = createAsyncThunk(
  "parking/getParkingInfo",
  async (args, { getState }) => {
    if (!args) {
      args = {};
    }
    const { longitude, latitude, val } = args;

    if (val) {
      const valError = validate([
        () => nullValidator(translate({ placeholder: "vk_6" }), longitude),
        () => nullValidator(translate({ placeholder: "vk_7" }), latitude),
      ]);
      if (valError) {
        toast.error(valError);
        return;
      }
    }

    try {
      const parkingInfo = await AppNetworkRest.getParkingInfo({
        latitude,
        longitude,
      });
      return parkingInfo;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const getLatLngParkingInfo = async ({ latitude, longitude }) => {
  try {
    const parkingInfo = await AppNetworkRest.getParkingInfo({
      latitude,
      longitude,
    });
    return parkingInfo;
  } catch (e) {
    console.log(e, "e");
  }
};

const getAreaParkingInfo = createAsyncThunk(
  "parking/getAreaParkingInfo",
  async (args, { getState }) => {
    if (!args) {
      args = {};
    }
    const { longitude, latitude, radius } = args;

    const valError = validate([
      () => nullValidator(translate({ placeholder: "vk_6" }), longitude),
      () => nullValidator(translate({ placeholder: "vk_7" }), latitude),
    ]);
    if (valError) {
      toast.error(valError);
      return;
    }

    try {
      const parkingInfos = await AppNetworkRest.getAreaParkingInfo({
        longitude,
        latitude,
        radius,
      });
      return parkingInfos;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const triggerNotification = createAsyncThunk(
  "parking/triggerNotification",
  async (args) => {
    const { longitude, latitude } = args;

    const str = await AppNetworkRest.triggerNotification({
      longitude,
      latitude,
    });

    if (str) {
      toast.success(str);
    }
  }
);

export const parkingSlice = createSlice({
  name: "parking",
  initialState,
  reducers: {
    setParkingInfo: (state, action) => {
      state.parkingInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getParkingInfo.fulfilled, (state, action) => {
      if (action.payload) {
        state.parkingInfo = action.payload;
      }
    });
    builder.addCase(getAreaParkingInfo.fulfilled, (state, action) => {
      if (action.payload) {
        const areaParkingInfos = action.payload.filter(
          (_) => !state.areaParkingInfos.map((__) => __.id).includes(_)
        );
        state.areaParkingInfos = [
          ...state.areaParkingInfos,
          ...areaParkingInfos,
        ];
      }
    });
    builder.addCase(triggerNotification.fulfilled, (state, action) => {});
  },
});

export const { setParkingInfo } = parkingSlice.actions;

export { getParkingInfo, getAreaParkingInfo, triggerNotification };

export { getLatLngParkingInfo };

export default parkingSlice.reducer;
