import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import localforage from "localforage";

import {
  loadLocaleENUS,
  loadLocaleSVSE,
  setIsLocalesLoaded,
} from "../controllers/locale";

import { configDB } from "../utils/globals";

import CLoader from "../components/loader";

function SLocales() {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    _init();
  }, []);

  const _init = async () => {
    configDB();

    let localeENUS = localStorage.getItem("AppStorage/localeENUS");
    let localeSVSE = localStorage.getItem("AppStorage/localeSVSE");

    if (!localeENUS || !localeSVSE) {
      setIsLoading(true);

      localeENUS = await loadLocaleENUS();
      localeSVSE = await loadLocaleSVSE();

      await localforage.setItem("localeENUS", localeENUS);
      await localforage.setItem("localeSVSE", localeSVSE);

      dispatch(setIsLocalesLoaded(true));
    } else {
      _syncLocales();

      dispatch(setIsLocalesLoaded(true));
    }
  };

  const _syncLocales = async () => {
    let localeENUS = await loadLocaleENUS();
    let localeSVSE = await loadLocaleSVSE();

    await localforage.setItem("localeENUS", localeENUS);
    await localforage.setItem("localeSVSE", localeSVSE);
  };

  return (
    <div className="flex flex-col items-center justify-center h-[100dvh] w-[100dvw]">
      {isLoading ? (
        <>
          <div className="h-[50px] w-[50px]">
            <CLoader />
          </div>
          <p className="text-[18px] font-[700]">Loading</p>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default SLocales;
