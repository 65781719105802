import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RouterProvider } from "react-router-dom";
import localforage from "localforage";

import { sync } from "../controllers/auth";
import { setRouter } from "../controllers/auth";
import { authRouter, userRouter } from "../utils/routes";
import initLocales from "../utils/i18n";

async function getRouter() {
  let appUser = await localforage.getItem("appUser"); // TODO: Fix it.
  if (!appUser) {
    return authRouter;
  } else {
    return userRouter;
  }
}

function SMain() {
  const router = useSelector((state) => state.auth.router);
  const isLocalesLoaded = useSelector((state) => state.locale.isLocalesLoaded);

  const dispatch = useDispatch();

  useEffect(() => {
    _syncAppUser();
  }, [router]);

  useEffect(() => {
    if (isLocalesLoaded) {
      _init();
    }
  }, [isLocalesLoaded]);

  const _init = async () => {
    const pass = await initLocales();
    if (pass) {
      const _router = await getRouter();
      dispatch(setRouter(_router));
    }
  };

  const _syncAppUser = async () => {
    if (router == userRouter) {
      let appUser = await localforage.getItem("appUser");
      if (appUser) {
        dispatch(sync(appUser));
      }
    }
  };

  return router ? <RouterProvider router={router} /> : <></>;
}

export default SMain;
