import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as AppNetworkRest from "../utils/network_rest";
import toast from "react-hot-toast";
import localforage from "localforage";

const initialState = {
  paymentPlans: [],
};

const getPaymentPlans = createAsyncThunk(
  "paymentPlans/getPaymentPlans",
  async (args, { getState }) => {
    try {
      const paymentPlans = await AppNetworkRest.getPaymentPlans();
      return paymentPlans;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const buyPaymentPlan = async ({ paymentPlanID, promoCode }) => {
  try {
    const clientSecret = await AppNetworkRest.buyPaymentPlan({
      payment_plan_id: paymentPlanID,
      payment_method: "klarna",
      promo_code: promoCode,
    });

    return clientSecret;
  } catch (e) {
    console.log(e, "e");
  }
};

const buyFullDiscountPaymentPlan = async ({ paymentPlanID, promoCode }) => {
  try {
    const str = await AppNetworkRest.buyPaymentPlan({
      payment_plan_id: paymentPlanID,
      payment_method: "full_discount",
      promo_code: promoCode,
    });

    if (str) {
      toast.success(str);
      let _appUser = await localforage.getItem("appUser");
      const appUser = await AppNetworkRest.sync(_appUser);

      if (appUser) {
        await appUser.cache();
      }
    }

    return str;
  } catch (e) {
    console.log(e, "e");
  }
};

export const paymentPlansSlice = createSlice({
  name: "faqs",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPaymentPlans.fulfilled, (state, action) => {
      if (action.payload) {
        state.paymentPlans = action.payload;
      }
    });
  },
});

export { getPaymentPlans, buyPaymentPlan, buyFullDiscountPaymentPlan };

export default paymentPlansSlice.reducer;
