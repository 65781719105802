import localforage from "localforage";
import { translate } from "./validators";

class AppResponse {
  success;
  data;
  error;
  errorMessage;

  constructor(props) {
    Object.assign(this, props);
  }

  static failure({ errorMessage = translate({ placeholder: "ar_1" }) }) {
    const obj = {
      success: false,
      errorMessage: errorMessage,
    };

    return new AppResponse(obj);
  }

  static parse(map) {
    const obj = {
      success: map["success"] ?? false,
      data: map["data"],
      error: map["error"],
      errorMessage: map["error_message"],
    };

    return new AppResponse(obj);
  }
}

class UserRoles {
  static user = "user";
  static admin = "admin";
}

class AppUser {
  id;
  email;
  phone;
  uuid;
  token;
  role;
  isCarConnected;
  isLocationConnected;
  createdAt;
  updatedAt;

  subscription;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      email: map["email"],
      phone: map["phone"],
      uuid: map["uuid"],
      token: map["token"],
      role: UserRoles.user,
      isCarConnected: map["is_car_connected"],
      isLocationConnected: map["is_location_connected"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],

      subscription:
        map["active_subscription"] != null
          ? Subscription.parse(map["active_subscription"])
          : null,
    };

    return new AppUser(obj);
  }

  cache = async () => {
    await localforage.setItem("appUser", this);
  };
}

class Location {
  latitude;
  longitude;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      latitude: map["latitude"],
      longitude: map["longitude"],
    };

    return new Location(obj);
  }
}

class Vehicle {
  id;
  make;
  model;
  year;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      make: map["make"] ?? "",
      model: map["model"] ?? "",
      year: map["year"] ?? "",
    };

    return new Vehicle(obj);
  }
}

class ParkingInfo {
  id;
  startTime;
  endTime;
  day;
  startMonth;
  endMonth;
  startDay;
  endDay;
  address;
  bbox;
  coordinates;
  serviceDayMessage;
  parkingRule;
  canStayParked;
  errorMessage;

  location;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      startTime: map["start_time"],
      endTime: map["end_time"],
      day: map["day"],
      startMonth: map["start_month"],
      endMonth: map["end_month"],
      startDay: map["start_day"],
      endDay: map["end_day"],
      address: map["address"],
      bbox: map["bbox"],
      coordinates: map["coordinates"],
      serviceDayMessage: map["service_day_message"],
      parkingRule: map["parking_rule"],
      canStayParked: map["can_stay_parked"],
      errorMessage: map["error_message"],
      location:
        map["location"] != null ? Location.parse(map["location"]) : null,
    };

    return new ParkingInfo(obj);
  }
}

class PaymentPlan {
  id;
  title;
  originalPrice;
  discountedPrice;
  months;
  isBestValue;
  freeMonths;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      title: map["title"],
      originalPrice: map["original_price"],
      discountedPrice: map["discounted_price"],
      months: map["months"],
      isBestValue: map["is_best_value"],
      freeMonths: map["free_months"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],
    };

    return new PaymentPlan(obj);
  }
}
class Subscription {
  id;
  title;
  expiry;
  status;
  paymentMethod;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      title: map["title"],
      expiry: map["expiry"] ? Date.parse(map["expiry"]) : null,
      status: map["status"],
      paymentMethod: map["payment_method"],
      createdAt: map["created_at"] ? Date.parse(map["created_at"]) : null,
      updatedAt: map["updated_at"] ? Date.parse(map["updated_at"]) : null,
    };

    return new Subscription(obj);
  }
}

class NotificationConfig {
  id;
  phoneNumber;
  email;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      phoneNumber: map["phone"],
      email: map["email"],
      createdAt: map["created_at"] ? Date.parse(map["created_at"]) : null,
      updatedAt: map["updated_at"] ? Date.parse(map["updated_at"]) : null,
    };

    return new NotificationConfig(obj);
  }
}

class FAQ {
  id;
  title;
  description;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      title: map["title"],
      description: map["description"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],
    };

    return new FAQ(obj);
  }
}

class PromoCode {
  id;
  title;
  discount;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      title: map["title"],
      discount: map["discount"],
      createdAt: map["created_at"] ? Date.parse(map["created_at"]) : null,
      updatedAt: map["updated_at"] ? Date.parse(map["updated_at"]) : null,
    };

    return new PromoCode(obj);
  }
}

export {
  AppResponse,
  AppUser,
  Location,
  Vehicle,
  ParkingInfo,
  PaymentPlan,
  Subscription,
  NotificationConfig,
  FAQ,
  PromoCode,
};
