import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CButton from "../components/button";

import OnBoarding2SVG from "../assets/images/onboarding_2.svg";
import CContainer from "../components/container";
import { paymentMethods } from "../utils/constants";

function SNotifications() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionExpiryDifference, setSubscriptionExpiryDifference] =
    useState(0);

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    let appUser = localStorage.getItem("AppStorage/appUser");
    appUser = JSON.parse(appUser);

    if (appUser.subscription.paymentMethod != paymentMethods.signup) {
      navigate("/subscription");
    } else {
      setIsLoading(false);

      const diff = appUser.subscription.expiry.valueOf() - new Date().valueOf();
      setSubscriptionExpiryDifference(diff);
    }
  };

  return (
    <CContainer>
      {isLoading ? (
        <></>
      ) : (
        <div className="flex flex-row items-center justify-center sm:h-[100dvh] h-[80dvh] w-[100dvw] bg-background">
          <div className="flex flex-col items-center justify-center md:w-[70dvw] sm:w-[60dvw] sm:h-[100dvh] h-[90dvh] w-[100dvw]">
            <div className="md:h-[75dvh] md:w-[45dvw] h-[100dvh] w-[100dvw] sm:h-[80dvh] sm:w-[60dvw] bg-container-background overflow-x-scroll sm:rounded-[10px] rounded-none">
              <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%]">
                <div className="sm:h-[5%] h-[40%]" />
                <img
                  src={OnBoarding2SVG}
                  className="sm:h-[50%] h-[40%] object-cover self-center"
                />
                <p className="text-[18px] font-[800] self-center">
                  {t("notifications_2")}
                </p>
                <div className="h-[2%]" />
                <p className="text-[16px] font-[500] self-center text-center">
                  {t("notifications_3")}
                </p>
                <div className="h-[2%]" />
                <p className="text-[16px] font-[500] self-center text-center">
                  {t("notifications_4")}
                </p>
                <div className="h-[2%]" />
                {subscriptionExpiryDifference > 0 ? (
                  <p className="text-[16px] font-[500] self-center text-center">
                    {t("notifications_5")}
                    {t("notifications_6")}
                    {parseInt(subscriptionExpiryDifference / 86400000)}
                    {t("notifications_7")}
                    {t("notifications_8")}
                  </p>
                ) : (
                  <p className="text-[16px] font-[500] self-center text-center">
                    {t("notifications_10")}
                  </p>
                )}
                <div className="h-[2%]" />
                <div className="relative h-[100%] w-[100%]">
                  <div className="absolute bottom-0 h-[100%] w-[100%] flex flex-col items-center justify-end">
                    <div className="h-[2%]" />
                    <CButton
                      className="w-[100%]"
                      text={
                        subscriptionExpiryDifference > 0
                          ? t("notifications_9")
                          : t("notifications_11")
                      }
                      onClick={() => {
                        if (subscriptionExpiryDifference > 0) {
                          navigate("/configure_notifications");
                        } else {
                          navigate("/payment_plans");
                        }
                      }}
                    />
                    <div className="h-[15%]" />
                    <p
                      className="text-[14px] font-[800] cursor-pointer"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      {t("notifications_12")}
                    </p>
                    <div className="h-[2%]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </CContainer>
  );
}

export default SNotifications;
