import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CButton from "../components/button";
import CContainer from "../components/container";
import CInput from "../components/input";

import CancelSVG from "../assets/images/cancel.svg";
import CheckSVG from "../assets/images/check.svg";

import {
  getPaymentPlans,
  buyPaymentPlan,
  buyFullDiscountPaymentPlan,
} from "../controllers/payment_plans";
import { getPromoCode, setPromoCode } from "../controllers/promo_codes";

function SPaymentPlans() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedPaymentPlanID, setSelectedPaymentPlanID] = useState(null);
  const [promoCodeTitle, setPromoCodeTitle] = useState("");

  const paymentPlans = useSelector((state) => state.paymentPlans.paymentPlans);
  const promoCode = useSelector((state) => state.promoCodes.promoCode);

  useEffect(() => {
    _init();
  }, []);

  useEffect(() => {
    if (paymentPlans?.length > 0) {
      setSelectedPaymentPlanID(paymentPlans[0].id);
    }
  }, [paymentPlans]);

  const _init = () => {
    dispatch(getPaymentPlans());
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start md:h-[85%] md:w-[45%] sm:h-[80%] sm:w-[60%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll">
          <div className="flex flex-col items-center justify-center w-[100%] px-[1%]">
            {paymentPlans.map((_) => (
              <CPPListTile
                paymentPlan={_}
                promoCode={promoCode}
                isSelected={selectedPaymentPlanID == _.id ? true : false}
                onClick={() => setSelectedPaymentPlanID(_.id)}
              />
            ))}
          </div>
          <div className="h-[2%]" />
          {promoCode ? (
            <div className="flex flex-ro">
              <p className="text-[16px] font-[600]">{promoCode.title}</p>
              <div className="w-[10px]" />
              <img
                src={CancelSVG}
                className="h-[20px] w-[20px] cursor-pointer"
                onClick={() => {
                  dispatch(setPromoCode(null));
                }}
              />
            </div>
          ) : (
            <></>
          )}
          <p className="text-[16px] font-[600]">{t("payment_plans_1_1")}</p>
          <div className="flex flex-row items-center justify-between w-[100%]">
            <CInput
              placeholder={t("payment_plans_1_2")}
              value={promoCodeTitle}
              className="w-[60%]"
              inputClassName="bg-[#DFE6FF]"
              onChange={(e) => {
                setPromoCodeTitle(e.target.value);
              }}
            />
            <CButton
              className={`w-[30%] ${
                promoCodeTitle != "" ? "!bg-[#252732]" : "!bg-[#D3D3D3]"
              }`}
              text={t("payment_plans_1_3")}
              onClick={() => {
                setPromoCodeTitle("");
                dispatch(
                  getPromoCode({
                    title: promoCodeTitle,
                  })
                );
              }}
            />
          </div>
          <div className="h-[2%]" />
          <p className="text-[16px] font-[500]">{t("payment_plans_2")}</p>
          <div className="h-[2%]" />
          <p className="text-[16px] font-[500]">{t("payment_plans_3")}</p>
          <div className="h-[2%]" />
          <p className="text-[16px] font-[500]">{t("payment_plans_4")}</p>
          <div className="h-[2%]" />
          <div className="grow" />
          <CheckoutForm1>
            <div id="my-checkout-container"></div>
          </CheckoutForm1>
          <CButton
            className="w-[100%]"
            text={t("payment_plans_5")}
            onClick={async () => {
              if (promoCode?.discount == 100) {
                await buyFullDiscountPaymentPlan({
                  paymentPlanID: selectedPaymentPlanID,
                  promoCode: promoCode?.title,
                });
                return;
              }
              const htmlSnippet = await buyPaymentPlan({
                paymentPlanID: selectedPaymentPlanID,
                promoCode: promoCode?.title,
              });
              if (htmlSnippet) {
                const checkoutContainer = document.getElementById(
                  "my-checkout-container"
                );
                checkoutContainer.innerHTML = htmlSnippet;
                const scriptsTags =
                  checkoutContainer.getElementsByTagName("script");
                for (let i = 0; i < scriptsTags.length; i++) {
                  const parentNode = scriptsTags[i].parentNode;
                  const newScriptTag = document.createElement("script");
                  newScriptTag.type = "text/javascript";
                  newScriptTag.text = scriptsTags[i].text;
                  parentNode.removeChild(scriptsTags[i]);
                  parentNode.appendChild(newScriptTag);
                }

                window.modal_klarna.showModal();
              }
            }}
          />
          <div className="h-[2%]" />
        </div>
      </div>
    </CContainer>
  );
}

function CPPListTile({ paymentPlan, promoCode, isSelected, onClick }) {
  const { t } = useTranslation();

  return (
    <div
      className={`flex flex-row ${
        isSelected ? "bg-[#EBF0FF]" : "bg-[#FFFFFF]"
      } sm:my-[2%] min-h-[12dvh] w-[100%] mb-[10px] rounded-[10px] relative`}
      onClick={onClick}
    >
      {isSelected ? (
        <div className="flex items-center justify-center h-[20px] w-[20px] bg-[#3DA406] rounded-[10px] top-[40%] left-[-10px] absolute">
          <img src={CheckSVG} className="h-[10px] w-[10px] top-[7%]" />
        </div>
      ) : (
        <></>
      )}
      <div className="flex flex-col justify-start items-center h-[100%] w-[100%] px-[2%]">
        <div className="flex flex-row justify-start items-center h-[100%] w-[100%]">
          <p className="text-[20px] font-[800]">{paymentPlan.title}</p>
          {paymentPlan.isBestValue ? (
            <>
              <div className="ml-[10%]" />
              <div className="flex flex-row bg-primary py-[5px] px-[5px]">
                <p className="text-[12px] font-[600] text-[#FFFFFF] whitespace-nowrap">
                  {t("payment_plans_6")}
                </p>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="h-[20%]" />
        {paymentPlan.freeMonths > 0 ? (
          <p className="text-[16px] text-primary font-[600] self-start">
            {t("payment_plans_6_1")}
            {paymentPlan.freeMonths}
            {t("payment_plans_7")}
          </p>
        ) : (
          <></>
        )}
      </div>

      <div className="flex flex-col items-end h-[100%] w-[100%] px-[2%] py-[2%]">
        {paymentPlan.originalPrice != paymentPlan.discountedPrice ? (
          <p className="text-[13px] font-[600] line-through">
            {paymentPlan.originalPrice} SEK
          </p>
        ) : (
          <></>
        )}
        <p
          className={`text-[16px] font-[900] ${
            promoCode ? "text-green-600" : ""
          }`}
        >
          {promoCode
            ? paymentPlan.discountedPrice -
              paymentPlan.discountedPrice * (promoCode.discount / 100)
            : paymentPlan.discountedPrice}{" "}
          SEK
        </p>
        <p className="text-[14px] font-[600]">
          {paymentPlan.months}
          {t("payment_plans_8")}
        </p>
      </div>
    </div>
  );
}

const CheckoutForm1 = ({ children }) => {
  return (
    <>
      <dialog id="modal_klarna" className="modal">
        <form method="dialog" className="modal-box bg-white">
          {children}
        </form>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
};

export default SPaymentPlans;
