import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as AppNetworkRest from "../utils/network_rest";

const initialState = {
  faqs: [],
};

const getFaqs = createAsyncThunk("faqs/getFaqs", async (args, { getState }) => {
  try {
    const faqs = await AppNetworkRest.getFaqs();
    return faqs;
  } catch (e) {
    console.log(e, "e");
  }
});

export const faqsSlice = createSlice({
  name: "faqs",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getFaqs.fulfilled, (state, action) => {
      if (action.payload) {
        state.faqs = action.payload;
      }
    });
  },
});

export { getFaqs };

export default faqsSlice.reducer;
