import { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PinInput from "react-pin-input";

import { login1, login2 } from "../controllers/auth";

import CButton from "../components/button";
import toast from "react-hot-toast";

const timers = [];

function SLogin2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const inputElement = useRef(null);
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, [inputElement]);

  const locationData = location.state;

  const isLoading = useSelector((state) => state.auth.isLoading);

  const [code, setCode] = useState("");
  const [seconds, setSeconds] = useState(59);

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    if (!locationData?.phone) {
      navigate("/");
      return;
    }

    initTimer();
  };

  const initTimer = () => {
    const timer = setInterval(() => {
      setSeconds((s) => s - 1);
    }, 1000);
    timers.push(timer);
  };

  useEffect(() => {
    if (seconds <= 0) {
      for (let i = 0; i < timers.length; i++) {
        clearInterval(timers[0]);
      }
      setSeconds(0);
    }
  }, [seconds]);

  return (
    <div className="flex flex-col items-center justify-center h-[100dvh] w-[100dvw]">
      <div className="flex flex-col md:h-[75dvh] md:w-[45dvw] h-[100dvh] w-[100dvw] sm:h-[80dvh] sm:w-[60dvw] bg-container-background p-[2%] overflow-x-scroll sm:rounded-[10px] rounded-none">
        <p className="text-[18px] font-[700] self-center">{t("login_2_1")}</p>
        <div className="flex flex-col justify-start h-[100%] w-[100%]">
          <p className="text-[18px] font-[800]">{t("login_2_2")}</p>
          <div className="h-[2%]" />
          <p className="text-[16px] font-[600]">{t("login_2_3")}</p>
          <div className="h-[10%]" />
          <PinInput
            length={4}
            initialValue={code}
            onChange={(value, index) => {
              setCode(value);
            }}
            type="numeric"
            inputMode="number"
            style={{
              padding: "10px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            inputStyle={{
              borderColor: "#4D5DFA",
              borderRadius: "5px",
              margin: "0 5%",
            }}
            inputFocusStyle={{
              borderColor: "#4D5DFA",
              borderRadius: "5px",
            }}
            autoSelect={true}
            ref={inputElement}
          />
          <div className="h-[10%]" />
          <p
            className="text-[14px] text-primary font-[600] self-center cursor-pointer"
            onClick={async () => {
              if (seconds == 0) {
                const res = await dispatch(
                  login1({
                    phone: locationData.phone,
                  })
                );
                setSeconds(59);
                initTimer();
              }
            }}
          >
            {`${t("login_2_4")} ${seconds == 0 ? "" : `in ${seconds}s`}`}
          </p>
          <div className="relative h-[100%] w-[100%]">
            <div className="absolute bottom-0 h-[100%] w-[100%] flex flex-col items-center justify-end">
              <CButton
                className="w-[100%] my-[14px]"
                text={t("login_2_5")}
                onClick={async () => {
                  if (code.length < 4) {
                    toast.error(t("login_2_6"));
                    return;
                  }
                  const res = await dispatch(
                    login2({
                      phone: locationData.phone,
                      code: code,
                    })
                  );
                  if (res.payload) {
                  }
                }}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SLogin2;
