import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setLocale } from "../controllers/locale";
import { locales } from "../utils/constants";

import CButton from "../components/button";

import SplashLogoSVG from "../assets/images/splash_logo.svg";
import FlagSwedenSVG from "../assets/images/flag_sweden.svg";
import FlagUKSVG from "../assets/images/flag_uk.svg";

function SSplash() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const locale = useSelector((state) => state.locale.locale);

  const [showButton, setShowButton] = useState(false);
  const [showLocale, setShowLocale] = useState(false);

  useEffect(() => {
    _init();
  }, []);

  const _init = async () => {
    let appUser = localStorage.getItem("AppStorage/appUser");
    if (!appUser) {
      setShowButton(true);
    }
    let locale = localStorage.getItem("locale");
    if (!locale) {
      setShowLocale(true);
    } else {
      dispatch(setLocale({ locale, i18n }));
      i18n.changeLanguage(locale);
    }
  };

  const selectLocaleHandler = (_locale) => {
    dispatch(setLocale({ locale: _locale, i18n }));
  };

  return (
    <div className="flex flex-col items-center justify-center h-[100dvh] w-[100dvw]">
      <div className="flex flex-col md:h-[75dvh] md:w-[45dvw] sm:h-[80dvh] sm:w-[60dvw] h-[100dvh] w-[100dvw] bg-[#FFFFFF] p-[2%] overflow-x-scroll sm:rounded-[10px] rounded-none">
        <div className="flex flex-col justify-start items-center h-[100%] w-[100%]">
          {showLocale ? (
            <div className="flex flex-row items-end justify-end w-[100%]">
              <div className="w-[2%]"></div>
              <img
                src={FlagSwedenSVG}
                className={`h-[50px] w-[50px] ${
                  locale == locales.english ? "opacity-[0.5]" : ""
                }`}
                onClick={() => selectLocaleHandler(locales.swedish)}
              />
              <div className="w-[2%]"></div>
              <img
                src={FlagUKSVG}
                className={`h-[50px] w-[50px] ${
                  locale == locales.swedish ? "opacity-[0.5]" : ""
                }`}
                onClick={() => selectLocaleHandler(locales.english)}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="h-[20%]"></div>
          <img src={SplashLogoSVG} className="w-[50%] object-cover" />
          <div className="h-[5%]"></div>
          <p className="text-[18px] font-[700]">{t("splash_0")}</p>
          <p className="text-[18px] font-[700]">{t("splash_0_1")}</p>
          <div className="h-[20%]"></div>
          {showButton ? (
            <div className="relative h-[100%] w-[100%]">
              <div className="absolute bottom-0 h-[100%] w-[100%] flex flex-col items-center justify-end">
                <CButton
                  className="my-[14px] w-[100%]"
                  text={t("splash_1")}
                  onClick={() => {
                    navigate("/login_1");
                  }}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default SSplash;
