import localforage from "localforage";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import L from "leaflet";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import { useMap, useMapEvents } from "react-leaflet/hooks";
import "leaflet/dist/leaflet.css";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

import CarMarkerSVG from "../assets/images/car_marker.svg";
import ParkingMarkerSVG from "../assets/images/parking_marker.svg";

import {
  getParkingInfo,
  getLatLngParkingInfo,
  getAreaParkingInfo,
  setParkingInfo,
} from "../controllers/parking";
import { pinVehicleLocation } from "../controllers/smartcar";

import CLoader from "../components/loader";
import CContainer from "../components/container";
import CButton from "../components/button";

import ParkingInfoSVG from "../assets/images/parking_info.svg";
import ServiceDayTimeSVG from "../assets/images/service_day_time.svg";
import ServiceDayCanStaySVG from "../assets/images/service_day_can_stay.svg";
import TVCenterSVG from "../assets/images/tv_center.svg";

const parkingDayColors = {
  monday: "#4E5EFA",
  tuesday: "#EA3424",
  wednesday: "#3DA406",
  thursday: "#FDD750",
  friday: "#7E7E7E",
};

const parkingDaysObject = {
  monday: "Mon",
  tuesday: "Tue",
  wednesday: "Wed",
  thursday: "Thu",
  friday: "Fri",
};

const parkingDaysArray = ["Mon", "Tue", "Wed", "Thu", "Fri"];

let mapInstance = null;

function STrackVehicle() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(18);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [clickMarker, setClickMarker] = useState(null);
  const [enabledParkingDays, setEnabledParkingDays] =
    useState(parkingDaysArray);
  const [areaParkingInfosToShow, setAreaParkingInfosToShow] = useState([]);
  const [selectedParkingInfo, setSelectedParkingInfo] = useState(null);
  let timer;

  const parkingInfo = useSelector((state) => state.parking.parkingInfo);
  const areaParkingInfos = useSelector(
    (state) => state.parking.areaParkingInfos
  );

  useEffect(() => {
    _init();
  }, []);

  useEffect(() => {
    if (parkingInfo) {
      setCenter({
        lat: parkingInfo.location.latitude - 0.0005,
        lng: parkingInfo.location.longitude,
      });
      setMarkerPosition({
        lat: parkingInfo.location.latitude,
        lng: parkingInfo.location.longitude,
      });
      dispatch(
        getAreaParkingInfo({
          latitude: parkingInfo.location.latitude,
          longitude: parkingInfo.location.longitude,
        })
      );
    }
  }, [parkingInfo]);

  useEffect(() => {
    let _areaParkingInfos = [...areaParkingInfos];
    _areaParkingInfos = _areaParkingInfos.filter((_) =>
      enabledParkingDays.includes(parkingDaysObject[_.day])
    );
    setAreaParkingInfosToShow(_areaParkingInfos);
  }, [areaParkingInfos]);

  const _init = async () => {
    const appUser = await localforage.getItem("appUser");
    const isConnected = appUser.isCarConnected || appUser.isLocationConnected;
    if (!isConnected) {
      navigate("/connect_vehicle");
      return;
    }
    dispatch(getParkingInfo());
  };

  const getPolylineColor = (parkingInfo) => {
    return parkingDayColors[parkingInfo.day];
  };

  const carMarkerIcon = new L.Icon({
    iconUrl: CarMarkerSVG,
    iconRetinaUrl: CarMarkerSVG,
    iconSize: new L.Point(60, null),
    iconAnchor: new L.Point(30, 50),
  });

  const parkingMarkerIcon = new L.Icon({
    iconUrl: ParkingMarkerSVG,
    iconRetinaUrl: ParkingMarkerSVG,
    iconSize: new L.Point(40, 40),
    iconAnchor: new L.Point(20, 40),
  });

  const loadParkingRules = (e) => {
    dispatch(
      getAreaParkingInfo({
        latitude: e.latlng.lat,
        longitude: e.latlng.lng,
        radius: 600,
      })
    );
  };

  const onClickParkingDay = (parkingDay) => {
    const _enabledParkingDays = [...enabledParkingDays];
    const index = _enabledParkingDays.indexOf(parkingDay);
    if (index > -1) {
      _enabledParkingDays.splice(index, 1);
    } else {
      _enabledParkingDays.push(parkingDay);
    }
    setEnabledParkingDays(_enabledParkingDays);
    let _areaParkingInfos = [...areaParkingInfos];
    _areaParkingInfos = _areaParkingInfos.filter((_) =>
      _enabledParkingDays.includes(parkingDaysObject[_.day])
    );
    setAreaParkingInfosToShow(_areaParkingInfos);
  };

  const onClickMap = async (e) => {
    setClickMarker({
      latitude: e.latlng.lat,
      longitude: e.latlng.lng,
    });
    loadParkingRules(e);
    const _parkingInfo = await getLatLngParkingInfo({
      latitude: e.latlng.lat,
      longitude: e.latlng.lng,
    });
    if (_parkingInfo) {
      setSelectedParkingInfo(_parkingInfo);
    }
  };

  const initTimer = () => {
    clearInterval(timer);
    timer = setTimeout(() => {
      const center = mapInstance.getCenter();
      dispatch(
        getAreaParkingInfo({
          latitude: center.lat,
          longitude: center.lng,
          radius: 600,
        })
      );
    }, 2000);
  };

  return (
    <CContainer>
      <div className="flex flex-col items-center justify-center h-[100dvh] w-[100dvw] bg-background">
        <div className="relative z-0 h-[100%] w-[100%]">
          {center ? (
            <MapContainer
              center={center}
              zoom={zoom}
              scrollWheelZoom={true}
              zoomControl={false}
              className="h-[100%] w-[100%] sticky"
            >
              <GetMapInstance />
              <Events onClick={onClickMap} moveEnd={initTimer} />
              <TileLayer
                url={`https://api.maptiler.com/maps/streets-v2/256/{z}/{x}/{y}@2x.png?key=${process.env.REACT_APP_MAPTILER_API_KEY}`}
              />
              <Marker
                position={[markerPosition.lat, markerPosition.lng]}
                icon={carMarkerIcon}
              >
                <Popup>Car location</Popup>
              </Marker>
              {clickMarker ? (
                <Marker
                  position={[clickMarker.latitude, clickMarker.longitude]}
                  icon={parkingMarkerIcon}
                >
                  <Popup>{`${clickMarker.latitude.toFixed(
                    4
                  )},${clickMarker.longitude.toFixed(4)}`}</Popup>
                </Marker>
              ) : (
                <></>
              )}
              {areaParkingInfosToShow.map((_) => (
                <Polyline
                  pathOptions={{ color: getPolylineColor(_), weight: 3 }}
                  positions={_.coordinates}
                />
              ))}
            </MapContainer>
          ) : (
            <CLoader />
          )}
          <div
            className={`absolute z-3 sm:top-[10%] top-[7%] flex flex-row items-center justify-evenly w-[100%] sm:rounded-[10px]`}
          >
            {parkingDaysArray.map((_, i) => (
              <CParkingDay
                text={_}
                indicatorStyle={{
                  backgroundColor: Object.values(parkingDayColors)[i],
                }}
                isActive={Object.values(enabledParkingDays).includes(_)}
                onClick={() => onClickParkingDay(_)}
              />
            ))}
          </div>
        </div>
        <MPinLocation
          selectedParkingInfo={selectedParkingInfo}
          successCallback={() => {
            setMarkerPosition({
              lat: selectedParkingInfo.location.latitude,
              lng: selectedParkingInfo.location.longitude,
            });
            setClickMarker(null);
            mapInstance.flyTo({
              lat: selectedParkingInfo.location.latitude,
              lng: selectedParkingInfo.location.longitude,
            });
            dispatch(setParkingInfo(selectedParkingInfo));
            setSelectedParkingInfo(null);
          }}
        />
        <CCenter
          offset={
            (selectedParkingInfo ?? parkingInfo)?.errorMessage
              ? CCenterOffsetTypes.minusEight
              : selectedParkingInfo
              ? CCenterOffsetTypes.plusFour
              : CCenterOffsetTypes.zero
          }
          onClick={() => {
            setClickMarker(null);
            setSelectedParkingInfo(null);
            if (parkingInfo) {
              setCenter({
                lat: parkingInfo.location.latitude - 0.0005,
                lng: parkingInfo.location.longitude,
              });
              if (mapInstance) {
                mapInstance.flyTo({
                  lat: parkingInfo.location.latitude - 0.0005,
                  lng: parkingInfo.location.longitude,
                });
              }
            }
          }}
        />
        <CParkingAddress
          parkingInfo={selectedParkingInfo ?? parkingInfo}
          pin={selectedParkingInfo ? true : false}
          offset={selectedParkingInfo ? true : false}
        />
        <CParkingServiceDay
          parkingInfo={selectedParkingInfo ?? parkingInfo}
          onClick={
            selectedParkingInfo
              ? () => {
                  if (clickMarker) {
                    window.modal_pin_location.showModal();
                  } else {
                    toast("Please click on a location on map first.", {
                      icon: "💡",
                    });
                  }
                }
              : null
          }
        />
      </div>
    </CContainer>
  );
}

function GetMapInstance({}) {
  const map = useMap();
  mapInstance = map;
  return null;
}

function Events({ onClick, moveEnd }) {
  const map = useMapEvents({
    click(e) {
      onClick(e);
    },
    moveend() {
      moveEnd();
    },
  });
}

function CParkingAddress({ parkingInfo, pin, offset }) {
  const { t } = useTranslation();
  return (
    <>
      {parkingInfo?.errorMessage ? (
        <></>
      ) : (
        <div
          className={`absolute z-1 ${
            offset
              ? "sm:bottom-[24%] bottom-[32%]"
              : "sm:bottom-[20%] bottom-[28%]"
          } sm:w-[50%] w-[100%] h-[15%] flex flex-col ${
            parkingInfo ? "items-start" : "items-center"
          } justify-center sm:px-[1%] px-[2%] sm:py-[2%] py-[3%] bg-[#4D5DFA] overflow-scroll sm:rounded-[10px] rounded-t-[20px]`}
        >
          {parkingInfo ? (
            <>
              <div className="flex flex-row justify-center items-center">
                <div className="flex flex-row justify-center items-center h-[50px] w-[50px] bg-container-background rounded-[25px] mr-[2%]">
                  <img src={ParkingInfoSVG} className="h-[20px] w-[20px]" />
                </div>
                <div className="flex flex-col justify-center items-start">
                  <p className="text-[16px] font-[600] text-white whitespace-nowrap">
                    {!pin ? t("track_vehicle_1") : t("track_vehicle_1_1")}
                  </p>
                  <p className="text-[16px] text-white whitespace-nowrap">
                    {parkingInfo.address}
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <p className="text-[16px] font-[900]">...</p>
            </>
          )}
        </div>
      )}
    </>
  );
}

function CParkingServiceDay({ parkingInfo, onClick }) {
  const { t } = useTranslation();
  return (
    <div
      className={`absolute z-2 sm:bottom-[2%] bottom-[10%] sm:w-[50%] w-[100%] ${
        onClick ? "h-[24%]" : "h-[20%]"
      }  flex flex-col ${
        parkingInfo ? "items-start" : "items-center"
      } sm:justify-center sm:px-[2%] px-[2%] sm:py-[1%] py-[2%] bg-white overflow-scroll sm:rounded-[10px] rounded-t-[20px]`}
    >
      {parkingInfo ? (
        parkingInfo?.errorMessage ? (
          <>
            <p className="text-[16px] font-[600]">
              {parkingInfo?.errorMessage}
            </p>
          </>
        ) : (
          <>
            <div className="h-[20px]" />
            <p className="text-[16px] font-[600]">{t("track_vehicle_2")}</p>
            <div className="flex flex-row justify-start items-center w-[100%]">
              <img
                src={ServiceDayTimeSVG}
                className="h-[16px] w-[16px] mr-[2%]"
              />
              <p className="text-[16px]">{parkingInfo.serviceDayMessage}</p>
            </div>
            <div className="h-[20px]" />
            <p className="text-[16px] font-[600]">{t("track_vehicle_3")}</p>
            <div className="flex flex-row justify-start items-center w-[100%]">
              <img
                src={ServiceDayCanStaySVG}
                className="h-[16px] w-[16px] mr-[2%]"
              />
              <p
                className={`text-[16px] ${
                  parkingInfo.canStayParked ? "text-[green]" : "text-[red]"
                }`}
              >
                {parkingInfo.canStayParked
                  ? t("track_vehicle_4")
                  : t("track_vehicle_5")}
              </p>
            </div>
            {onClick ? (
              <>
                <div className="h-[20px]" />
                <CButton
                  className="w-[100%]"
                  onClick={onClick}
                  text="Confirm location"
                />
              </>
            ) : null}
          </>
        )
      ) : (
        <>
          <p className="text-[16px] font-[900]">...</p>
        </>
      )}
    </div>
  );
}

class CCenterOffsetTypes {
  static zero = 0;
  static plusFour = 4;
  static minusEight = 12;
}

function CCenter({ onClick, offset }) {
  return (
    <div
      className={`absolute z-3 ${
        offset == CCenterOffsetTypes.zero
          ? "sm:bottom-[36%] bottom-[45%]"
          : offset == CCenterOffsetTypes.plusFour
          ? "sm:bottom-[40%] bottom-[49%]"
          : offset == CCenterOffsetTypes.minusEight
          ? "sm:bottom-[28%] bottom-[37%]"
          : ""
      } self-start flex flex-col justify-center items-center w-[50px] h-[50px] sm:ml-[25%] ml-[2%] bg-white overflow-scroll rounded-[25px] cursor-pointer`}
      onClick={onClick}
    >
      <img
        src={TVCenterSVG}
        className="h-[20px] w-[20px]"
        style={{ filter: "opacity(0.5) drop-shadow(0 0 0 #4E5EFA)" }}
      />
    </div>
  );
}

const MPinLocation = ({ selectedParkingInfo, successCallback }) => {
  const dispatch = useDispatch();

  return (
    <>
      <dialog id="modal_pin_location" className="modal">
        <form method="dialog" className="modal-box bg-white">
          <div className="flex flex-col items-center justify-center bg-white">
            <p className="text-[18px] font-[800]">Are you sure?</p>
            <div className="h-[20px]" />
            <p className="text-[14px] font-[600]">
              The selected location will be saved as your pin location.
            </p>
            <div className="h-[30px]" />
            <CButton
              className="w-[20hvw]"
              text={"Confirm"}
              onClick={async () => {
                if (selectedParkingInfo) {
                  const success = await dispatch(
                    pinVehicleLocation(selectedParkingInfo.location)
                  );

                  if (success) {
                    window.modal_pin_location.close();
                    successCallback();
                  }
                } else {
                  toast(
                    "Selected pin doesn't have any parking info available.",
                    {
                      icon: "💡",
                    }
                  );
                }
              }}
            />
            <div className="h-[10px]" />
            <p
              className="text-[14px] font-[600] text-[#E53935] cursor-pointer"
              onClick={() => {
                window.modal_pin_location.close();
              }}
            >
              Cancel
            </p>
            <div className="h-[10px]" />
          </div>
        </form>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
};

function CParkingDay({ indicatorStyle, text, isActive, onClick }) {
  return (
    <div
      className={`flex flex-row items-center justify-center px-[2%] py-[0.5%] rounded-[20px] bg-white cursor-pointer ${
        isActive
          ? "border-solid border-[#4E5EFA] border-2"
          : "border-solid border-transparent border-2"
      }`}
      onClick={onClick}
    >
      <div
        className={`h-[10px] w-[10px] rounded-[5px] mr-[5px]`}
        style={indicatorStyle}
      ></div>
      <p>{text}</p>
    </div>
  );
}

export default STrackVehicle;
