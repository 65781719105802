import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import * as AppNetworkRest from "../utils/network_rest";

const { nullValidator, validate, translate } = require("../utils/validators");

const initialState = {
  vehicle: null,
};

const login = createAsyncThunk("smartcar/login", async (args, { getState }) => {
  try {
    const link = await AppNetworkRest.smartCarLogin();
    return link;
  } catch (e) {
    console.log(e, "e");
  }
});

const syncCode = async (args) => {
  try {
    const { code } = args;

    const valError = validate([
      () => nullValidator(translate({ placeholder: "vk_2" }), code),
    ]);
    if (valError) {
      toast.error(valError);
      return;
    }

    const appUser = await AppNetworkRest.smartCarSyncCode({ code: code });
    if (appUser) {
      await appUser.cache();
      window.location = "/track_vehicle";
    }
  } catch (e) {
    console.log(e, "e");
  }
};

const getVehicle = createAsyncThunk(
  "smartcar/getVehicle",
  async (args, { getState }) => {
    try {
      const vehicle = await AppNetworkRest.getVehicle();
      return vehicle;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const diconnectVehicle = createAsyncThunk(
  "smartcar/diconnectVehicle",
  async (args, { getState }) => {
    try {
      const str = await AppNetworkRest.disconnectVehicle();

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const pinVehicleLocation = createAsyncThunk(
  "smartcar/pinVehicleLocation",
  async (args, { getState }) => {
    try {
      const appUser = await AppNetworkRest.pinVehicleLocation(args);

      if (appUser) {
        await appUser.cache();
        toast.success(translate({ placeholder: "toast_3" }));
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

export const smartcarSlice = createSlice({
  name: "smartcar",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      if (action.payload) {
        window.addEventListener("message", (data) => {
          data = data.data;
          if (data.type == "smartcar-connect") {
            syncCode({ code: data.code });
          }
        });
        let myWindow;
        myWindow = window.open(action.payload, "", "height=600,width=400");
        if (
          !myWindow ||
          myWindow.closed ||
          typeof myWindow.closed == "undefined"
        ) {
          setTimeout(() => {
            myWindow = window.open(action.payload, "_blank");
          });
        }
      }
    });
    builder.addCase(getVehicle.fulfilled, (state, action) => {
      if (action.payload) {
        state.vehicle = action.payload;
      }
    });
  },
});

export { login, getVehicle, diconnectVehicle, pinVehicleLocation };

export default smartcarSlice.reducer;
