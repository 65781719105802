import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CContainer from "../components/container";

import { logout } from "../controllers/auth";

import SManageSubscriptionSVG from "../assets/images/s_manage_subscription.svg";
import SChangeConnectedCar from "../assets/images/s_change_connected_car.svg";
import SLanguage from "../assets/images/s_language.svg";
import SLogoutSVG from "../assets/images/s_logout.svg";

function SSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [goToConnectVehicle, setGoToConnectVehicle] = useState(false);

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    let appUser = localStorage.getItem("AppStorage/appUser");
    appUser = JSON.parse(appUser);
    if (!appUser.isCarConnected) {
      setGoToConnectVehicle(true);
    }
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start md:h-[75%] md:w-[45%] sm:h-[80%] sm:w-[60%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll">
          <div className="h-[2%]" />
          <p className="text-[13px] font-[600]">{t("settings_2")}</p>
          <CSListTile
            icon={SManageSubscriptionSVG}
            title={t("settings_4")}
            onClick={() => navigate("/configure_notifications")}
          />
          <CSListTile
            icon={SChangeConnectedCar}
            title={t("settings_5")}
            onClick={() => {
              if (goToConnectVehicle) {
                navigate("/connect_vehicle");
              } else {
                navigate("/change_vehicle");
              }
            }}
          />
          <p className="text-[13px] font-[600]">{t("settings_6")}</p>
          <CSListTile
            icon={SLanguage}
            title={t("settings_9")}
            onClick={() => {
              navigate("/change_language");
            }}
          />
          <CSListTile
            icon={SLogoutSVG}
            title={t("settings_10")}
            onClick={() => dispatch(logout())}
          />
        </div>
      </div>
    </CContainer>
  );
}

function CSListTile({ icon, title, onClick }) {
  return (
    <div
      className="flex flex-row items-center justify-start sm:my-[0.5%] my-[1%] p-[1%] w-[100%] cursor-pointer"
      onClick={onClick}
    >
      <img src={icon} className="h-[20px] w-[20px]" />
      <div className="w-[10px]" />
      <p className="text-[16px] font-[600]">{title}</p>
    </div>
  );
}

export default SSettings;
