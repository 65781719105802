import localforage from "localforage";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function SHome() {
  const navigate = useNavigate();

  useEffect(() => {
    _init();
  }, []);

  const _init = async () => {
    const appUser = await localforage.getItem("appUser");
    const isConnected = appUser.isCarConnected || appUser.isLocationConnected;

    if (isConnected) {
      navigate("/track_vehicle");
    } else {
      navigate("/connect_vehicle");
    }
  };

  return <></>;
}

export default SHome;
