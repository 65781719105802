import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CButton from "../components/button";

import CContainer from "../components/container";

function SPaymentSuccess() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <CContainer>
      <div className="flex flex-row items-center justify-center sm:h-[100dvh] h-[80dvh] w-[100dvw] bg-background">
        <div className="flex flex-col items-center justify-center md:w-[70dvw] sm:w-[60dvw] sm:h-[100dvh] h-[90dvh] w-[100dvw]">
          <div className="md:h-[75dvh] md:w-[45dvw] h-[100dvh] w-[100dvw] sm:h-[80dvh] sm:w-[60dvw] bg-container-background overflow-x-scroll sm:rounded-[10px] rounded-none">
            <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%]">
              <div className="sm:h-[5%] h-[40%]" />
              <p className="text-[18px] font-[800] text-green-600 self-center">
                {t("payment_success_2")}
              </p>
              <div className="h-[2%]" />
              <p className="text-[18px] font-[500]">{t("payment_success_3")}</p>
              <div className="h-[8%]" />
              <div className="relative h-[100%] w-[100%]">
                <div className="absolute bottom-0 h-[100%] w-[100%] flex flex-col items-center justify-end">
                  <div className="h-[2%]" />
                  <CButton
                    className="w-[100%]"
                    text={t("payment_success_4")}
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                  <div className="h-[2%]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CContainer>
  );
}

export default SPaymentSuccess;
