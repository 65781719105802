import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth";
import smartcarReducer from "./smartcar";
import parkingReducer from "./parking";
import localeReducer from "./locale";
import paymentPlansReducer from "./payment_plans";
import subscriptionReducer from "./subscription";
import notificationConfigReducer from "./notification_config";
import controlBarReducer from "./control_bar";
import faqsReducer from "./faqs";
import contactSupportReducer from "./contact_support";
import promoCodeReducer from "./promo_codes";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    smartcar: smartcarReducer,
    parking: parkingReducer,
    locale: localeReducer,
    paymentPlans: paymentPlansReducer,
    subscription: subscriptionReducer,
    notificationConfig: notificationConfigReducer,
    controlBar: controlBarReducer,
    faqs: faqsReducer,
    contactSupport: contactSupportReducer,
    promoCodes: promoCodeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
