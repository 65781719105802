import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setLocale } from "../controllers/locale";

import { locales } from "../utils/constants";

import CButton from "../components/button";
import CContainer from "../components/container";

function SChangeLanguage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const locale = useSelector((state) => state.locale.locale);

  return (
    <CContainer>
      <div className="flex flex-col items-center justify-center sm:h-[100dvh] h-[90dvh] w-[100dvw]">
        <div className="flex flex-col md:h-[75dvh] md:w-[45dvw] sm:h-[80dvh] sm:w-[60dvw] h-[100dvh] w-[100dvw] bg-container-background p-[2%] overflow-x-scroll sm:rounded-[10px] rounded-none">
          <p className="text-[18px] font-[700] self-center">
            {t("change_language_1")}
          </p>
          <div className="flex flex-col justify-start h-[100%] w-[100%]">
            <div className="h-[5%]" />
            <CChangeLanguage
              locale={locales.swedish}
              text={t("change_language_1_1")}
              isSelected={locale == locales.swedish}
            />
            <div className="h-[5%]" />
            <CChangeLanguage
              locale={locales.english}
              text={t("change_language_1_2")}
              isSelected={locale == locales.english}
            />
          </div>
          <div className="relative h-[100%] w-[100%]">
            <div className="absolute bottom-0 h-[100%] w-[100%] flex flex-col items-center justify-end">
              <CButton
                className="w-[100%] my-[14px]"
                text={t("change_language_2")}
                onClick={async () => {
                  navigate(-1);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </CContainer>
  );
}

function CChangeLanguage({ locale, text, isSelected }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <div
      className="flex flex-row items-center justify-start h-[10%] py-[5%] rounded-[20px] border-[1px] border-[#E0E2E7] border-solid cursor-pointer"
      onClick={() => {
        dispatch(setLocale({ locale, i18n }));
      }}
    >
      <label className="label px-[2%]">
        <input
          type="checkbox"
          checked={isSelected}
          className="checkbox checkbox-success"
        />
      </label>
      <p className="text-[16px] font-[600]">{text}</p>
    </div>
  );
}

export default SChangeLanguage;
