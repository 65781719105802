import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setSelectedRouteIndex } from "../controllers/control_bar";

import LogoSVG from "../assets/images/logo.svg";
import BNVHomeSelectedSVG from "../assets/images/bnv_home_selected.svg";
import BNVHomeUnselectedSVG from "../assets/images/bnv_home_unselected.svg";
import BNVNotificationsSelectedSVG from "../assets/images/bnv_notifications_selected.svg";
import BNVNotificationsUnselectedSVG from "../assets/images/bnv_notifications_unselected.svg";
import BNVSettingsSelectedSVG from "../assets/images/bnv_settings_selected.svg";
import BNVSettingsUnselectedSVG from "../assets/images/bnv_settings_unselected.svg";
import BNVHelpSelectedSVG from "../assets/images/bnv_help_selected.svg";
import BNVHelpUnselectedSVG from "../assets/images/bnv_help_unselected.svg";

const icons = [
  {
    title: "appbar_0",
    route: "/",
    selectedIcon: BNVHomeSelectedSVG,
    unSelectedIcon: BNVHomeUnselectedSVG,
  },
  {
    title: "appbar_1",
    route: "/notifications",
    selectedIcon: BNVNotificationsSelectedSVG,
    unSelectedIcon: BNVNotificationsUnselectedSVG,
  },
  {
    title: "appbar_2",
    route: "/settings",
    selectedIcon: BNVSettingsSelectedSVG,
    unSelectedIcon: BNVSettingsUnselectedSVG,
  },
  {
    title: "appbar_3",
    route: "/help",
    selectedIcon: BNVHelpSelectedSVG,
    unSelectedIcon: BNVHelpUnselectedSVG,
  },
];

function CAppbar({ className }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedRouteIndex = useSelector(
    (state) => state.controlBar.selectedRouteIndex
  );

  return (
    <div
      className={`sm:flex flex-row justify-evenly items-center h-[8%] w-[100%] bg-white absolute top-0 hidden ${className}`}
    >
      <div className="sm:flex flex-row justify-start items-center h-[10%] w-[50%]">
        <div className="pl-[1%]" />
        <img
          src={LogoSVG}
          className="h-[45px] w-[100px] cursor-pointer"
          onClick={() => {
            navigate("/");
            dispatch(setSelectedRouteIndex(0));
          }}
        />
      </div>
      <div className="sm:flex flex-row justify-end items-center h-[10%] w-[50%]">
        {icons.map((_, index) => (
          <CABListTile
            title={t(_.title)}
            isSelected={index + 1 == selectedRouteIndex}
            onClick={() => {
              navigate(_.route);
              dispatch(setSelectedRouteIndex(index + 1));
            }}
            selectedIcon={_.selectedIcon}
            unSelectedIcon={_.unSelectedIcon}
          />
        ))}
      </div>
    </div>
  );
}

function CABListTile({
  title,
  isSelected,
  onClick,
  selectedIcon,
  unSelectedIcon,
}) {
  return (
    <>
      <div className="px-[5%]">
        <div
          className="flex flex-row justify-end items-center cursor-pointer"
          onClick={onClick}
        >
          <img
            src={isSelected ? selectedIcon : unSelectedIcon}
            className="h-[20px] w-[20px]"
          />
          <div className="px-[5px]" />
          <p
            className={`text-[16px] font-[600] ${
              isSelected ? "text-primary" : "text-black"
            }`}
          >
            {title}
          </p>
        </div>
      </div>
    </>
  );
}

export default CAppbar;
