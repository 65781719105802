import axios from "axios";
import toast from "react-hot-toast";
import localforage from "localforage";

import {
  AppResponse,
  AppUser,
  ParkingInfo,
  PaymentPlan,
  Subscription,
  Vehicle,
  NotificationConfig,
  FAQ,
  PromoCode,
} from "./models";

const RequestTypes = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

// const serverURL = "http://192.168.1.7:4001";
// const serverURL = "https://qarlie-dev-server.umarbinayaz.com";
const serverURL = "https://server.qarlie.com";

async function login1({ phone }) {
  try {
    const url = `${serverURL}/auth/login_1`;

    const headers = await _headers({ parseAppUser: false });

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        phone,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function login2({ phone, code }) {
  try {
    const url = `${serverURL}/auth/login_2`;

    const headers = await _headers({ parseAppUser: false });

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { phone, code },
    });

    if (res.success) {
      let appUser = AppUser.parse(res.data);
      return appUser;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function sync(appUser) {
  try {
    const url = `${serverURL}/auth/sync`;

    const headers = await _headers();
    headers["authorization"] = `Bearer ${appUser.token}`;

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
    });

    if (res.success) {
      let appUser = AppUser.parse(res.data);
      return appUser;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function smartCarLogin() {
  try {
    const url = `${serverURL}/smartcar/login`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      let link = res.data;
      return link;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function smartCarSyncCode({ code }) {
  try {
    const url = `${serverURL}/smartcar/sync_code`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { code },
    });

    if (res.success) {
      let appUser = AppUser.parse(res.data);
      return appUser;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function getParkingInfo({ latitude, longitude }) {
  try {
    const url = `${serverURL}/parking/info`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { latitude, longitude },
    });

    if (res.success) {
      let parkingInfo = ParkingInfo.parse(res.data);
      return parkingInfo;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function getAreaParkingInfo({ latitude, longitude, radius }) {
  try {
    const url = `${serverURL}/parking/area`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { latitude, longitude, radius },
    });

    if (res.success) {
      const parkingInfos = res.data.map((_) => ParkingInfo.parse(_));
      return parkingInfos;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function triggerNotification({ latitude, longitude }) {
  try {
    const url = `${serverURL}/notifications/trigger`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { latitude, longitude },
    });

    if (res.success) {
      let str = res.data;
      return str;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function getPaymentPlans() {
  try {
    const url = `${serverURL}/payment_plans`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const paymentPlans = res.data.map((_) => PaymentPlan.parse(_));
      return paymentPlans;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function buyPaymentPlan({ payment_plan_id, payment_method, promo_code }) {
  try {
    const url = `${serverURL}/payment_plans/buy`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { payment_plan_id, payment_method, promo_code },
    });

    if (res.success) {
      const clientSecret = res.data;
      return clientSecret;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function getSubscription() {
  try {
    const url = `${serverURL}/subscriptions`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
      orotund: false,
    });

    if (res.success) {
      const subscription = Subscription.parse(res.data);
      return subscription;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function cancelSubscription() {
  try {
    const url = `${serverURL}/subscriptions/cancel`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function getVehicle() {
  try {
    const url = `${serverURL}/smartcar/vehicle`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const vehicle = Vehicle.parse(res.data);
      return vehicle;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function disconnectVehicle() {
  try {
    const url = `${serverURL}/smartcar/disconnect`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function pinVehicleLocation({ latitude, longitude }) {
  try {
    const url = `${serverURL}/smartcar/pin_vehicle_location`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { latitude, longitude },
    });

    if (res.success) {
      let appUser = AppUser.parse(res.data);
      return appUser;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function getNotificationConfig() {
  try {
    const url = `${serverURL}/notification_configs`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const notificationConfig = NotificationConfig.parse(res.data);
      return notificationConfig;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function updateNotificationConfig({ phone, email }) {
  try {
    const url = `${serverURL}/notification_configs/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { phone, email },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function getFaqs() {
  try {
    const url = `${serverURL}/faqs`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const faqs = res.data.map((_) => FAQ.parse(_));
      return faqs;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createContactSupport({ name, email, message }) {
  try {
    const url = `${serverURL}/contact_support`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { name, email, message },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function loadLocaleENUS() {
  try {
    const url = `${serverURL}/locales/web/en_US.json`;

    const headers = await _headers();

    const res = await axios({
      method: RequestTypes.get,
      url: url,
      headers: headers,
    });

    const data = res.data;

    return data;
  } catch (e) {
    return null;
  }
}

async function loadLocaleSVSE() {
  try {
    const url = `${serverURL}/locales/web/sv_SE.json`;

    const headers = await _headers();

    const res = await axios({
      method: RequestTypes.get,
      url: url,
      headers: headers,
    });

    const data = res.data;

    return data;
  } catch (e) {
    return null;
  }
}

async function getPromoCode({ title }) {
  try {
    const url = `${serverURL}/promo_codes/read_one`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { title },
    });

    if (res.success) {
      const promoCode = PromoCode.parse(res.data);
      return promoCode;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function _request({ requestType, url, headers, body, orotund = true }) {
  let appResponse;

  try {
    const res = await axios({
      method: requestType,
      url: url,
      data: body,
      headers: headers,
    });

    const data = res.data;

    appResponse = AppResponse.parse(data);
  } catch (e) {
    appResponse = AppResponse.failure();
  }

  if (!appResponse.success && orotund) {
    toast.error(appResponse.errorMessage);
  }

  return appResponse;
}

async function _headers(props = { parseAppUser: true }) {
  const locale = localStorage.getItem("locale");

  const obj = {};

  obj["x-access-token"] = `Basic ${process.env.REACT_APP_X_ACCESS_TOKEN}`;
  obj["locale"] = locale;
  obj["client"] = "web";

  if (props.parseAppUser) {
    const appUser = await localforage.getItem("appUser");

    if (appUser != null) {
      obj["authorization"] = `Bearer ${appUser.token}`;
    }
  }

  return obj;
}

export {
  login1,
  login2,
  sync,
  triggerNotification,
  smartCarLogin,
  smartCarSyncCode,
  getParkingInfo,
  getAreaParkingInfo,
  getPaymentPlans,
  buyPaymentPlan,
  getSubscription,
  cancelSubscription,
  getVehicle,
  disconnectVehicle,
  pinVehicleLocation,
  getNotificationConfig,
  updateNotificationConfig,
  getFaqs,
  createContactSupport,
  loadLocaleENUS,
  loadLocaleSVSE,
  getPromoCode,
};
