import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import * as AppNetworkRest from "../utils/network_rest";

const { phoneValidator, validate, translate } = require("../utils/validators");

const initialState = {
  notificationConfig: null,
};

const getNotificationConfig = createAsyncThunk(
  "subscription/getNotificationConfig",
  async (args, { getState }) => {
    try {
      const notificationConfig = await AppNetworkRest.getNotificationConfig();
      return notificationConfig;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const updateNotificationConfig = createAsyncThunk(
  "subscription/updateNotificationConfig",
  async (args, { getState }) => {
    try {
      const { phone } = args;

      if (phone) {
        const valError = validate([
          () => phoneValidator(translate({ placeholder: "vk_1" }), phone),
        ]);
        if (valError) {
          toast.error(valError);
          return false;
        }
      }

      const str = await AppNetworkRest.updateNotificationConfig(args);

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
      return false;
    }
  }
);

export const notificationConfigSlice = createSlice({
  name: "notificationConfig",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getNotificationConfig.fulfilled, (state, action) => {
      if (action.payload) {
        state.notificationConfig = action.payload;
      }
    });
    builder.addCase(updateNotificationConfig.fulfilled, (state, action) => {});
  },
});

export { getNotificationConfig, updateNotificationConfig };

export default notificationConfigSlice.reducer;
